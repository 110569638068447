import { createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from 'styles/theme';

const stylesDrawerContainer = makeStyles(theme => ({
  drawerContainer: {
    padding: 0,
    width: '100%',
    overflowY: 'scroll',
    paddingBottom: '120px',
    position: 'fixed',
    top: '65px',
    left: '0px',
    backgroundColor: theme.palette.system.white,
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '45%',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.system.navBorder,
      borderRight: '2px solid',
      borderRightColor: theme.palette.system.white,
      backgroundClip: 'padding-box',
      borderRadius: '25px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      margin: '20px',
      marginBottom: '80px',
    },
  },
}));

const StylesDrawerList = {
  padding: '0px',
};

const StylesMenuDrawerList = {
  ...StylesDrawerList,
};

const StylesMenuIconRow = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ' :hover ': {
    backgroundColor: theme.palette.system.backgroundGray,
  },
};

const StylesListItem = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: '47px',
  height: '32px',
  padding: '0px',
  paddingLeft: '25px',
  marginTop: '7px',
  marginBottom: '7px',
  minWidth: 'initial',
};

const StylesDivider = { width: '90%', margin: '18px auto' };

const StylesMenuIconText = {
  paddingLeft: '14px',
  marginTop: '7px',
  marginBottom: '7px',
};

const StylesMenuSectionText = {
  paddingTop: '18px',
  paddingLeft: '23px',
};

const commonFontProperties = {
  fontSize: 14,
  fontFamily: 'Open Sans, sans-serif',
};

let mobileNavTheme = createTheme({
  typography: {
    body1: {
      ...commonFontProperties,
      fontWeight: 500,
      color: theme.palette.system.midnightBlue,
    },
    body2: {
      ...commonFontProperties,
      color: theme.palette.system.coolGray,
    },
  },
});

const StylesBottomNavAction = { minWidth: '60px', pl: '24px' };

const StylesContactsAction = {
  ...StylesBottomNavAction,
  transform: 'scale(1)',
};

export {
  stylesDrawerContainer,
  StylesDrawerList,
  StylesMenuIconRow,
  StylesMenuDrawerList,
  StylesListItem,
  StylesDivider,
  StylesMenuIconText,
  StylesMenuSectionText,
  mobileNavTheme,
  StylesBottomNavAction,
  StylesContactsAction,
};
