import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { ShiftItem } from 'components/ScheduleEventInfoPanel/ShiftItem';
import ShowMoreLink from 'components/ScheduleEventInfoPanel/showMoreLink';
import { getTimezoneName } from 'helpers/getTimezoneName';

const INITIAL_LIMIT = 3;

const noScheduleMessageSection = (
  <Grid
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="200px"
  >
    <Typography variant="subtitle1" fontStyle="italic" color="system.coolGray">
      You have no upcoming shifts
    </Typography>
  </Grid>
);

const UpcomingShifts = ({ shifts, timeZoneName }) => (
  <>
    <Grid
      item
      py={2}
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant={shifts.length > 0 ? 'subtitle1' : 'body1'}
        color={shifts.length > 0 ? 'system.midnightBlue' : 'system.grayText'}
        sx={{ maxWidth: { lg: '270px' } }}
      >
        {'Upcoming Shifts'}
      </Typography>
      {timeZoneName && (
        <Typography
          variant="body1"
          fontStyle="italic"
          color="system.coolGray"
          data-testid="time-zone-name"
        >
          {`(${timeZoneName})`}
        </Typography>
      )}
    </Grid>
    {shifts?.length > 0 ? (
      <>
        {shifts.map((shift, i) => (
          <React.Fragment key={i}>
            <ShiftItem shift={shift} />
            {i < shifts.length - 1 && (
              <Divider sx={{ width: '100%', height: '1px' }} />
            )}
          </React.Fragment>
        ))}
      </>
    ) : (
      noScheduleMessageSection
    )}
  </>
);

const PastShifts = ({ shifts, showMoreLess, showMore, limit }) => (
  <>
    <Grid
      item
      py={2}
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="subtitle1"
        color="system.midnightBlue"
        sx={{ maxWidth: { lg: '270px' } }}
      >
        {'Past Shifts'}
      </Typography>
    </Grid>
    {shifts.slice(0, limit).map((shift, i) => (
      <React.Fragment key={i}>
        <ShiftItem shift={shift} />
        {i < (limit <= INITIAL_LIMIT ? limit : shifts.length) - 1 && (
          <Divider sx={{ width: '100%', height: '1px' }} />
        )}
      </React.Fragment>
    ))}
    <Grid width="100%" justifyContent="center" display="flex">
      {shifts.length > INITIAL_LIMIT && (
        <ShowMoreLink
          onClickShowMore={() => showMoreLess}
          moreOrLess={showMore}
          moreText="Show More"
        />
      )}
    </Grid>
  </>
);

const ShiftsComponent = ({ upcomingShifts, pastShifts, facilityName }) => {
  const [limit, setLimit] = React.useState(INITIAL_LIMIT);
  const [showMore, setShowMore] = React.useState(true);
  const [timeZoneName, setTimeZoneName] = React.useState<string | undefined>(
    '',
  );

  React.useEffect(() => {
    if (upcomingShifts && upcomingShifts.length > 0) {
      if (upcomingShifts[0]?.startDateTime) {
        setTimeZoneName(getTimezoneName(upcomingShifts[0]?.startDateTime));
      }
    } else if (pastShifts && pastShifts.length > 0) {
      if (pastShifts[0]?.startDateTime) {
        setTimeZoneName(getTimezoneName(pastShifts[0]?.startDateTime));
      }
    }
  }, [pastShifts, upcomingShifts]);

  const showMoreLess = () => {
    showMore ? setLimit(pastShifts.length) : setLimit(INITIAL_LIMIT);
    setShowMore(!showMore);
  };

  return (
    <Grid container id="schedule-shifts-infos-drawer">
      <Grid item pb={4} xs={12}>
        <Typography
          color="system.midnightBlue"
          variant="subtitle1"
          data-testid="facility-name"
        >
          {facilityName}
        </Typography>
      </Grid>
      <UpcomingShifts shifts={upcomingShifts} timeZoneName={timeZoneName} />
      {pastShifts?.length > 0 && (
        <PastShifts
          shifts={pastShifts}
          showMoreLess={showMoreLess}
          showMore={showMore}
          limit={limit}
        />
      )}
    </Grid>
  );
};

export default ShiftsComponent;
