import { Card, Grid, Typography, Link } from '@mui/material';
import { AutoSubIndicatorIcon } from 'assets/icons/AutoSubIndicatorIcon';
import {
  useMobileMediaQuery,
  useSmMediaQuery,
} from 'containers/MobileDesktopContainer/useMediaQuery';
import { PreferenceTypes } from 'enums/preferences';
import { IAutoSubmitShortcut } from 'interfaces/Jobs/IAutoSubmitShortcut';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { trackEvent } from 'services/logging/appInsights';
import { theme } from 'styles/theme';

const AutoSubmitShortcut: React.FC<IAutoSubmitShortcut> = props => {
  const {
    id = 'AutoShortcut1',
    titleText = '',
    linkText = 'Update Auto-Submission',
    splitText = false,
    trackEventName = 'Web_JobSearch_AutoSubmission_Card1',
    trackWebResponsiveEventName = 'Responsive_JobSearch_AutoSubmission_Card1',
    trackEventMessage = '',
    linkOnly = false,
    titleTextAfter = '',
    clickFrom = 'autoSubmitShortcutJob',
    selectedId = '',
  } = props;

  const isMobileDevice = useMobileMediaQuery();
  const isTabletDevice = useSmMediaQuery();

  const history = useHistory();
  const isWebDevices = !isMobileDevice && !isTabletDevice;

  const handleLinkClick = useCallback(() => {
    history.push(
      `/${AppRouteNames.PREFERENCES}?categoryId=${PreferenceTypes.AUTO_SUBMISSION}`,
      {
        from: clickFrom,
        selectedId,
      },
    );
    if (!trackEventName || !trackWebResponsiveEventName) return;
    trackEvent(isWebDevices ? trackEventName : trackWebResponsiveEventName, {
      message: trackEventMessage ?? '',
    });
  }, [
    clickFrom,
    history,
    isWebDevices,
    selectedId,
    trackEventMessage,
    trackEventName,
    trackWebResponsiveEventName,
  ]);

  if (linkOnly) {
    return (
      <Typography
        onClick={handleLinkClick}
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          wordBreak: 'break-word',
          maxWidth: '100%',
        }}
      >
        {titleText && (
          <span
            style={{
              marginRight: '4px',
            }}
          >
            {titleText}
          </span>
        )}
        <span
          style={{
            cursor: 'pointer',
            color: theme.palette.system.skyBlue,
          }}
        >
          {linkText}
        </span>
        {titleTextAfter && (
          <span
            style={{
              marginLeft: '4px',
            }}
          >
            {titleTextAfter}
          </span>
        )}
      </Typography>
    );
  }

  return (
    <Grid container marginBottom={2} onClick={handleLinkClick}>
      <Card
        id={id}
        key={id}
        sx={{
          border: `1px solid  ${theme.palette.system.border}`,
          borderRadius: '10px',
          padding: '12px 24px',
          minHeight: '63px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Grid
          container
          item
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          gap={2}
        >
          <Grid item display="flex" alignItems="center">
            <AutoSubIndicatorIcon
              color={theme.palette.system.navIconGray}
              height="32px"
              width="32px"
            />
          </Grid>
          <Grid container item sx={{ width: '100%', alignItems: 'center' }}>
            <Typography
              color={theme.palette.system.subTitleBlack}
              sx={{
                display: splitText ? 'flex' : 'inline',
                flexDirection: splitText ? 'column' : 'row',
              }}
            >
              {titleText}{' '}
              <Typography
                sx={{
                  display: 'inline',
                  cursor: 'pointer',
                  color: theme.palette.system.skyBlue,
                  font: 'body1',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '20px',
                }}
              >
                <span>{linkText}</span>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default AutoSubmitShortcut;
