import ICountry from 'interfaces/Common/ICountry';

export const defaultCountry: ICountry = {
  countryCode: 'USA',
  countryDialingCode: '1',
  countryName: 'United States',
};

export const defaultCountryEmpty: ICountry = {
  countryCode: '',
  countryDialingCode: '',
  countryName: '',
};
