import {
  PayloadAction,
  PrepareAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';
import { IACSJobDetails } from 'interfaces/Jobs/IACSJobDetails';
import { IACSJobRequest } from 'interfaces/Jobs/IACSJobRequest';
import { IACSJobSuggestionRequest } from 'interfaces/Jobs/IACSJobSuggestionRequest';
import {
  IACSJobSuggestionResponse,
  IJobSuggestion,
} from 'interfaces/Jobs/IACSJobSuggestionResponse';
import { ICognitiveJobState } from './cognitiveJobState';

const initialState: ICognitiveJobState = {
  jobCount: 0,
  jobs: [],
  filters: [],
  selectedJobId: undefined,
  selectedJobDetails: {} as IACSJobDetails,
  pageSize: '40',
  requestFilter: {} as IACSJobRequest,
  isLoading: false,
  suggestedLocations: [],
  suggestedSkillset: { communitySuggestions: [] },
  scrollPosition: 0,
  currentSort: undefined,
  defaultFilter: [],
  defaultFilterSet: false,
  recommendedJobs: [] as IACSJobDetails[],
  isFromRecommendedJobsPage: false,
  isFromHomePage: false,
  isFromRecommendedJobToHomePage: false,
  scrollToTop: false,
};

const fetchJobs = createAction(
  'FETCH_ACS_JOBS',
  function prepare(fetchJobACSRequest: IACSJobRequest) {
    return {
      payload: {
        ...fetchJobACSRequest,
      },
    };
  },
);

const getJobDetailsAction = createAction<number>('GET_JOB_DETAILS');
const fetchJobSuggestionsAction = createAction<
  PrepareAction<IACSJobSuggestionRequest>
>(
  'FETCH_JOB_SUGGESTIONS',
  function prepare(searchRequest: IACSJobSuggestionRequest) {
    return {
      payload: searchRequest,
    };
  },
);

const fetchDefaultFilterAction = createAction<
  PrepareAction<IACSJobSuggestionRequest[]>
>(
  'FETCH_DEFAULT_JOB_FILTER',
  function prepare(defaultFilterRequest: IACSJobSuggestionRequest[]) {
    return {
      payload: defaultFilterRequest,
    };
  },
);

const fetchRecommendedJobs = createAction<number>('FETCH_RECOMMENDED_JOBS');

const cognitiveJobSlice = createSlice({
  name: 'cognitiveJobs',
  initialState,
  reducers: {
    setJobsACS(state, action: PayloadAction<ICognitiveJobState>) {
      if (
        action.payload?.requestFilter?.pageNumber !== '1' &&
        action.payload?.jobs
      ) {
        state.jobs = [...state.jobs, ...action.payload.jobs];
      } else {
        state.jobs = action.payload?.jobs;
      }
      state.jobCount = action.payload?.jobCount;
      state.filters = action.payload?.filters;
    },
    resetSelectedJobDetails(state) {
      return {
        ...state,
        selectedJobId: undefined,
        selectedJobDetails: {} as IACSJobDetails,
      };
    },
    setSelectedJobDetails(state, action: PayloadAction<IACSJobDetails>) {
      return {
        ...state,
        selectedJobId: action.payload.jobID,
        selectedJobDetails: action.payload,
      };
    },
    setRequestFilter(state, action: PayloadAction<IACSJobRequest>) {
      return {
        ...state,
        requestFilter: action.payload,
      };
    },
    setLoadingState(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSuggestedLocations(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        suggestedLocations: action.payload,
      };
    },
    setSuggestedSkills(
      state,
      action: PayloadAction<IACSJobSuggestionResponse>,
    ) {
      return {
        ...state,
        suggestedSkillset: action.payload,
      };
    },
    setScrollPosition(state, action: PayloadAction<number | undefined>) {
      return {
        ...state,
        scrollPosition: action.payload,
      };
    },
    setCurrentSort(state, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        currentSort: action.payload,
      };
    },
    setDefaultFilter(state, action: PayloadAction<IJobSuggestion[]>) {
      return {
        ...state,
        defaultFilter: action.payload,
        defaultFilterSet:
          action.payload && action.payload.length > 0 ? true : false,
      };
    },
    resetCurrentFilter(state) {
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          currentFilterType: undefined,
        },
      };
    },
    setKeywordSearchTerm(state, action: PayloadAction<string>) {
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          keywordSearch: action.payload,
        },
      };
    },
    setLocationSearchTerm(state, action: PayloadAction<string>) {
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          locationSearch: action.payload,
        },
      };
    },
    setRecommendedJobs(state, action: PayloadAction<IACSJobDetails[]>) {
      return {
        ...state,
        recommendedJobs: action.payload,
      };
    },
    setAppliedDateForJob(state) {
      state.selectedJobDetails!.appliedDate = new Date(
        Date.now(),
      ).toISOString();
    },
    setIsFromRecommendedJobsPage(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isFromRecommendedJobsPage: action.payload,
      };
    },
    setIsFromHomePage(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isFromHomePage: action.payload,
      };
    },
    setIsFromRecommendedJobToHomePage(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isFromRecommendedJobToHomePage: action.payload,
      };
    },
    setScrollToTop(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        scrollToTop: action.payload,
      };
    },
  },
});

export const cognitiveJobActions = {
  ...cognitiveJobSlice.actions,
  fetchJobs,
  getJobDetailsAction,
  fetchJobSuggestionsAction,
  fetchDefaultFilterAction,
  fetchRecommendedJobs,
};

export default cognitiveJobSlice.reducer;
