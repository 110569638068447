import { IAlliedDisciplineAndSpecialties } from 'components/Jobs/JobSearch/JobPreference/IAlliedDisciplineAndSpecialties';
import { ExpertiseFormField } from 'components/Jobs/JobSearch/JobPreference/IExpertiseFormField';
import { IFacilityType } from 'components/Jobs/JobSearch/JobPreference/IFacilityType';
import { IFieldOptions } from 'components/Jobs/JobSearch/JobPreference/IFieldOptions';
import { IJobType } from 'components/Jobs/JobSearch/JobPreference/IJobType';
import { IShiftLength } from 'components/Jobs/JobSearch/JobPreference/IShiftLength';
import { IShiftTime } from 'components/Jobs/JobSearch/JobPreference/IShiftTime';
import {
  alterAllOptions,
  anyFacilityType,
  anyJobType,
  anyShiftLength,
  anyShiftTime,
  areAllOptionsChecked,
  areAllOptionsUnchecked,
} from 'components/Jobs/JobSearch/JobPreference/utils';
import { searchRadiusEnum } from 'enums/searchRadiusEnums';
import { Shifts } from 'enums/shiftId';
import { UserTypeId } from 'enums/userTypeId';
import { AssignmentSummaryItem } from 'interfaces/Assignment/AssignmentSummaryItem';
import { IDisciplinesAndSpecialty } from 'interfaces/Lookup/IDisciplinesAndSpecialty';
import { IDropdownOption } from 'interfaces/Props/IDropdownOption';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ISuccessDisciplineResponse } from 'types/DisciplineResponse';
import {
  IJobDisciplineAndSpecialties,
  ILocationSearch,
  ILocationSearchResponse,
  IShift,
} from 'types/UserPreference';
import {
  getInitialShiftLengths,
  getInitialShiftTimes,
} from 'utils/userPreferenceHelper';

export const locationToLocationResponse = (
  locationList: ILocationSearch[],
): ILocationSearchResponse[] => {
  return locationList.map(location => {
    return {
      searchText: location.searchText,
      cityInfo: location.cityInfo
        ? {
            cityId: location.cityInfo?.cityId,
            city: location.cityInfo.city,
          }
        : null,
      stateInfo: location.stateInfo
        ? {
            stateId: location.stateInfo?.stateId,
            stateName: location.stateInfo?.stateDescription,
          }
        : null,
      regionInfo: location.regionInfo
        ? {
            regionId: location.regionInfo?.regionId,
            region: location.regionInfo.region,
          }
        : null,
    };
  });
};

export const mapLocationsForRadius = (
  locations: ILocationSearch[],
): ILocationSearch[] => {
  return locations?.map(location => {
    const radius = location?.cityInfo?.radius;

    switch (radius) {
      case searchRadiusEnum.FIRST_RADIUS:
        return {
          ...location,
          cityInfo: {
            ...location.cityInfo,
            radius: searchRadiusEnum.SECOND_RADIUS,
          },
        };
      case searchRadiusEnum.SECOND_RADIUS:
        return {
          ...location,
          cityInfo: {
            ...location.cityInfo,
            radius: searchRadiusEnum.THIRD_RADIUS,
          },
        };
      case searchRadiusEnum.THIRD_RADIUS:
        return {
          ...location,
          cityInfo: {
            ...location.cityInfo,
            radius: searchRadiusEnum.FOURTH_RADIUS,
          },
        };
    }
    return location;
  });
};

export const mapExpertiseFormFields = (
  expertiseList: IJobDisciplineAndSpecialties[],
  userProfile: IUserProfile,
  disciplineList: ISuccessDisciplineResponse,
): {
  expertiseFormFields: ExpertiseFormField[];
  alliedDisciplineAndSpecialties: IAlliedDisciplineAndSpecialties[];
} => {
  let expertiseFormFields = [] as ExpertiseFormField[];
  let alliedDisciplineAndSpecialties = [] as IAlliedDisciplineAndSpecialties[];
  let selectedDisciplineAndSpecialties: any[] = [];

  if (userProfile.userDisciplineTypeId === UserTypeId.ALLIED) {
    expertiseList.forEach(expertise => {
      let idx = disciplineList.items.findIndex(
        item =>
          item.disciplineId.toString() === expertise.disciplineId.toString(),
      );
      if (idx > -1) {
        let index = selectedDisciplineAndSpecialties.findIndex(
          selected =>
            selected.disciplineId.toString() ===
            expertise.disciplineId.toString(),
        );
        if (index === -1) {
          selectedDisciplineAndSpecialties.push({
            ...disciplineList.items[idx],
            checked: expertise.checked,
          });
        }
      }
    });

    userProfile?.disciplinesAndSpecialties.forEach(item => {
      let idx = disciplineList.items.findIndex(
        disc => item.disciplineId.toString() === disc.disciplineId.toString(),
      );
      if (idx > -1) {
        let index = selectedDisciplineAndSpecialties.findIndex(
          selected =>
            selected.disciplineId.toString() === item.disciplineId.toString(),
        );
        if (index === -1) {
          selectedDisciplineAndSpecialties.push({
            ...disciplineList.items[idx],
            checked: expertiseList.length === 0,
          });
        }
      }
    });

    let arr = selectedDisciplineAndSpecialties.map(discipline => {
      let b = discipline.specialities.map(specialty => {
        let idx = expertiseList.findIndex(
          exp =>
            exp.specialtyId === specialty.specialtyId &&
            exp.disciplineId === discipline.disciplineId &&
            exp.discipline === discipline.disciplineDescription,
        );
        const isChecked =
          idx > -1 &&
          expertiseList[idx].disciplineId === discipline.disciplineId;
        return { ...specialty, checked: isChecked };
      });
      return { ...discipline, specialities: b };
    });

    let userPreferenceDisciplineAndSpecialities: any[] = [...arr];

    userPreferenceDisciplineAndSpecialities?.forEach((discipline, index) => {
      const specialtyOptions: IDropdownOption[] = [];

      discipline?.specialities?.forEach(specialty =>
        specialtyOptions.push({
          key: specialty.specialtyId.toString(),
          value: specialty.specialtyDescription,
        }),
      );

      const isVerified = userProfile?.disciplinesAndSpecialties.find(item => {
        return (
          item.disciplineId.toString() === discipline.disciplineId.toString()
        );
      });

      const anySpecialty: boolean =
        discipline.checked &&
        discipline.specialities.every(spec => spec.checked === false);

      alliedDisciplineAndSpecialties.push({
        disciplineId: discipline.disciplineId.toString(),
        discipline: discipline.disciplineDescription,
        specialtyOptions,
        anySpecialty: anySpecialty,
        verified: !!isVerified,
        edit: false,
        specialities: discipline.specialities,
        checked: discipline.checked,
      });
    });

    const alliedDiscAndSpecialties = _.cloneDeep(
      alliedDisciplineAndSpecialties,
    );

    alliedDiscAndSpecialties.sort((discpSpec, discSpec2) => {
      if (discpSpec?.verified && !discSpec2?.verified) {
        return -1;
      } else if (!discpSpec?.verified && discSpec2?.verified) {
        return 1;
      }
      return 0;
    });
    alliedDisciplineAndSpecialties = alliedDiscAndSpecialties;
  } else if (userProfile.userDisciplineTypeId === UserTypeId.NURSE) {
    userProfile.disciplinesAndSpecialties.forEach(disc => {
      let idx = expertiseList.findIndex(
        exp =>
          exp.disciplineId === disc.disciplineId &&
          exp.specialtyId === disc.specialtyId,
      );
      if (idx === -1) {
        selectedDisciplineAndSpecialties.push({
          ...disc,
          checked: expertiseList.length === 0 ? true : false,
        });
      }
    });

    expertiseList.forEach(exp => {
      selectedDisciplineAndSpecialties.push(exp);
    });

    const isVerified = (discId: number, specId: number) => {
      let idx = userProfile.disciplinesAndSpecialties.findIndex(disc => {
        return disc.disciplineId === discId && disc.specialtyId === specId;
      });
      return idx > -1;
    };

    const specOptions = (discId: number): IDropdownOption[] => {
      let options: IDropdownOption[] = [];
      let idx = disciplineList.items.findIndex(
        item => item.disciplineId.toString() === discId.toString(),
      );
      if (idx > -1) {
        let newArray: IDropdownOption[] = disciplineList.items[
          idx
        ].specialities.map(spec => {
          return {
            key: spec.specialtyId.toString(),
            value: spec.specialtyDescription,
          };
        });
        options = newArray;
      }
      return options;
    };

    selectedDisciplineAndSpecialties.forEach((exp, idx) => {
      const newExpertise: ExpertiseFormField = {
        discipline_name: 'discipline_' + idx,
        discipline: exp.disciplineId.toString(),
        disciplineValue: exp.discipline,
        specialty_name: 'specialty_' + idx,
        specialty: exp.specialtyId ? exp.specialtyId?.toString() : ' ',
        specialtyValue: exp.specialty ? exp.specialty?.toString() : ' ',
        checked: exp.checked,
        edit: false,
        verified: isVerified(exp.disciplineId, exp.specialtyId || 0),
        specialtyOptions: specOptions(exp.disciplineId),
      };
      expertiseFormFields.push(newExpertise);
    });

    expertiseFormFields.sort((expertise, expertise2) => {
      if (expertise?.verified && !expertise2?.verified) {
        return -1;
      } else if (!expertise?.verified && expertise2?.verified) {
        return 1;
      }
      return 0;
    });
  }

  return { expertiseFormFields, alliedDisciplineAndSpecialties };
};

export const mapjobTypes = (jobTypes: IJobType[]) => {
  let tempJobTypes = _.cloneDeep(jobTypes);
  if (jobTypes && jobTypes.length) {
    const areAllOptionsActive = areAllOptionsChecked(tempJobTypes, 0);
    if (areAllOptionsActive) {
      tempJobTypes = [_.cloneDeep(anyJobType(false)), ...tempJobTypes];
    } else if (areAllOptionsUnchecked(tempJobTypes, 0)) {
      tempJobTypes = [_.cloneDeep(anyJobType(true)), ...tempJobTypes];
      tempJobTypes = alterAllOptions(tempJobTypes, false) as IJobType[];
    } else {
      tempJobTypes = [_.cloneDeep(anyJobType(false)), ...tempJobTypes];
    }
  } else {
    tempJobTypes = [
      { isActive: true, note: '', type: 'Any Job Type', typeId: 0 },
      {
        isActive: false,
        note: '13 Week Travel Assignment',
        type: '13 Week Travel Assignment',
        typeId: 100,
      },
      {
        isActive: false,
        note: 'Critical Staffing',
        type: 'Critical Staffing',
        typeId: 200,
      },
      { isActive: false, note: 'Strike', type: 'Strike', typeId: 300 },
      { isActive: false, note: 'Vaccine', type: 'Vaccine', typeId: 400 },
      {
        isActive: false,
        note: 'Clinical Fellowship',
        type: 'Clinical Fellowship',
        typeId: 500,
      },
    ];
  }
  return tempJobTypes;
};

export const mapStartDate = (
  assignmentDetails: AssignmentSummaryItem[],
  startDate: Moment | string,
) => {
  const today = moment.utc(new Date(), 'ddd MMM DD YYYY HH:mm:ssZ');
  let startDateValue = today;
  if (startDate) {
    startDateValue = moment(startDate);
  }

  if (assignmentDetails) {
    const onAssignmentRecord = assignmentDetails.find(
      item => item.activityStatus === 'On Assignment',
    );

    const startDt = moment.utc(moment(startDate), 'ddd MMM DD YYYY HH:mm:ssZ');
    if (onAssignmentRecord) {
      if (
        startDt.isAfter(
          moment.utc(
            moment(onAssignmentRecord.endDate),
            'ddd MMM DD YYYY HH:mm:ssZ',
          ),
        )
      ) {
        startDateValue = startDt;
      } else {
        startDateValue = moment
          .utc(moment(onAssignmentRecord.endDate), 'ddd MMM DD YYYY HH:mm:ssZ')
          .add(1, 'days');
      }
    } else if (startDt.isAfter(today)) {
      startDateValue = startDt;
    }
    if (startDateValue.isBefore(today)) {
      startDateValue = today;
    }
  }
  return startDateValue;
};

export const mapShifts = (shifts: IShift[]) => {
  const tempShifts = _.cloneDeep(shifts);
  const shiftTimes = [] as IShiftTime[];
  const shiftLengths = [] as IShiftLength[];

  tempShifts?.forEach(item => {
    if (
      item.typeId === Shifts.DAY ||
      item.typeId === Shifts.MID ||
      item.typeId === Shifts.NIGHT
    ) {
      shiftTimes.push(_.cloneDeep(item));
    }
    if (
      item.typeId === Shifts.FE_HOURS ||
      item.typeId === Shifts.FT_HOURS ||
      item.typeId === Shifts.TT_HOURS
    ) {
      shiftLengths.push(_.cloneDeep(item));
    }
  });

  // Shift Times
  let tempShiftTimes = _.cloneDeep(shiftTimes);
  if (shiftTimes.length) {
    if (areAllOptionsChecked(tempShiftTimes, 0)) {
      tempShiftTimes = [_.cloneDeep(anyShiftTime(false)), ...tempShiftTimes];
    } else if (areAllOptionsUnchecked(tempShiftTimes, 0)) {
      tempShiftTimes = [_.cloneDeep(anyShiftTime(true)), ...tempShiftTimes];
      tempShiftTimes = alterAllOptions(tempShiftTimes, false) as IShiftTime[];
    } else {
      tempShiftTimes = [_.cloneDeep(anyShiftTime(false)), ...tempShiftTimes];
    }
  } else {
    tempShiftTimes = getInitialShiftTimes();
  }

  tempShiftTimes = tempShiftTimes?.map(obj =>
    obj.typeId === Shifts.MID ? { ...obj, type: 'Evening' } : obj,
  );

  // Shift Lengths
  let tempShiftLengths = _.cloneDeep(shiftLengths);
  if (shiftLengths.length) {
    if (areAllOptionsChecked(tempShiftLengths, 0)) {
      tempShiftLengths = [_.cloneDeep(anyShiftLength(false)), ...shiftLengths];
    } else if (areAllOptionsUnchecked(tempShiftLengths, 0)) {
      tempShiftLengths = [_.cloneDeep(anyShiftLength(true)), ...shiftLengths];
      tempShiftLengths = alterAllOptions(
        tempShiftLengths,
        false,
      ) as IShiftLength[];
    } else {
      tempShiftLengths = [_.cloneDeep(anyShiftLength(false)), ...shiftLengths];
    }
  } else {
    tempShiftLengths = getInitialShiftLengths();
  }

  return { shiftTimes: tempShiftTimes, shiftLengths: tempShiftLengths };
};

export const mapFacilityTypes = (facilityTypes: IFieldOptions[]) => {
  let tempFacilityTypes = _.cloneDeep(facilityTypes);
  if (tempFacilityTypes && tempFacilityTypes.length) {
    if (areAllOptionsChecked(tempFacilityTypes, 0)) {
      tempFacilityTypes = [
        _.cloneDeep(anyFacilityType(false)),
        ...tempFacilityTypes,
      ];
    } else if (areAllOptionsUnchecked(tempFacilityTypes, 0)) {
      tempFacilityTypes = [
        _.cloneDeep(anyFacilityType(true)),
        ...tempFacilityTypes,
      ];
      tempFacilityTypes = alterAllOptions(
        tempFacilityTypes,
        false,
      ) as IFacilityType[];
    } else {
      tempFacilityTypes = [
        _.cloneDeep(anyFacilityType(false)),
        ...tempFacilityTypes,
      ];
    }
  } else {
    tempFacilityTypes = [
      { type: 'Any Facility Type', isActive: true, typeId: 0 },
      { type: 'Magnet', isActive: false, typeId: 200 },
      { type: 'Teaching', isActive: false, typeId: 300 },
      { type: 'Trauma', isActive: false, typeId: 400 },
    ];
  }
  return tempFacilityTypes;
};

const isChecked = (
  verifiedExpertise: IDisciplinesAndSpecialty,
  autoSubmitExpertise: IDisciplinesAndSpecialty[],
): boolean => {
  let index = -1;
  index = autoSubmitExpertise.findIndex(
    exp =>
      exp.disciplineId === verifiedExpertise.disciplineId &&
      exp.specialtyId === verifiedExpertise.specialtyId,
  );
  return index > -1;
};

export const mapExpertiseForAutoSubmit = (
  userProfileDisciplinesAndSpecialties: IDisciplinesAndSpecialty[],
  autoSubmitDisciplineAndSpecialties: IDisciplinesAndSpecialty[],
): IJobDisciplineAndSpecialties[] => {
  const expertise = userProfileDisciplinesAndSpecialties.map(exp => {
    return {
      disciplineId: exp.disciplineId,
      discipline: exp.discipline,
      specialtyId: exp.specialtyId,
      specialty: exp.specialty,
      checked: isChecked(exp, autoSubmitDisciplineAndSpecialties),
    };
  });

  return expertise;
};
