import React from 'react';
import { IScheduleShift } from 'interfaces/Assignment/AssignmentDetail';
import ShiftsComponent from 'components/ScheduleEventInfoPanel/ShiftsComponent';

type ScheduleShiftsInfos = {
  pastShifts: IScheduleShift[];
  upcomingShifts: IScheduleShift[];
};

interface IScheduleEventInfoPanelProps {
  scheduleShiftsInfos?: ScheduleShiftsInfos;
  facilityName: string;
}

const ScheduleEventInfoPanel = ({
  scheduleShiftsInfos,
  facilityName,
}: IScheduleEventInfoPanelProps) => {
  return (
    <ShiftsComponent
      upcomingShifts={scheduleShiftsInfos?.upcomingShifts}
      pastShifts={scheduleShiftsInfos?.pastShifts}
      facilityName={facilityName}
    />
  );
};

export { ScheduleEventInfoPanel };
