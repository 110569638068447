import {
  Badge,
  Box,
  ListItemIcon,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AvatarTextVariant } from 'enums/avatarTextVariant';
import { MenuIcons } from 'enums/menuIcons';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { setPhotoEditingState } from 'store/slices/photo/photoSlice';
import { setProfileMenuState } from 'store/slices/profileMenu/profileMenuSlice';
import { desktopWidth } from 'styles/media';
import MenuIcon from '../../../assets/icons/NavMenuIcon/NavMenuIcon';
import mapMenuIconsection from '../../../utils/mapMenuIconNameSection';
import ProfileAvatar from '../ProfileAvatar';
import {
  StyleMenuOptionText,
  StylesListItem,
  StylesMenuOption,
  StylesMenuOptionMobile,
  StylesMenuPaperProps,
  StylesMobileMenuPaperProps,
  StylesProfileMenu,
  StylesProfileMenuInner,
  StylesProfileMenuInnerMobile,
  StylesProfileMenuMobile,
  StylesProfileRow,
  StylesProfileRowImageWrapper,
  StylesProfileRowMobile,
  StylesTravelerIdWrapper,
  StylesUserInfoContainer,
  theme,
} from './ProfileMenu.styles';
import { UserStatusTypeId } from 'enums/userStatusTypeId';

export interface IProfileMenuProps {
  toggleDrawer: (
    event: React.KeyboardEvent | React.MouseEvent,
    open: boolean,
  ) => void;
  open: boolean;
}

const ProfileMenu: FC<IProfileMenuProps> = ({ toggleDrawer, open }) => {
  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );

  const icons = [
    {
      name: MenuIcons.Profile,
      icon: <MenuIcon icon={MenuIcons.Profile} />,
      transform: 'scale(1)',
    },
    {
      name: MenuIcons.Preferences,
      icon: <MenuIcon icon={MenuIcons.Preferences} />,
      transform: 'scale(1)',
    },
    {
      name: MenuIcons.Promotions,
      icon: <MenuIcon icon={MenuIcons.Promotions} />,
      transform: 'scale(1)',
    },
    {
      name: MenuIcons.AMNBadge,
      icon: <MenuIcon icon={MenuIcons.AMNBadge} />,
      transform: 'scale(1)',
    },
    {
      name: MenuIcons.Signout,
      icon: <MenuIcon icon={MenuIcons.Signout} />,
      transform: 'scale(1)',
    },
  ];
  const anchorEl = document.querySelector('#profile-icon-wrapper');

  let matchesMinWidth1024 = useMediaQuery(desktopWidth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isOpen: boolean = useAppSelector(state => state.profileMenu.isOpen);
  const user: IUserProfile | undefined = useAppSelector(state => {
    if (state.userProfile) {
      return state.userProfile;
    }
  });
  const userName: string = useAppSelector(
    state => state.userProfile.firstName + ' ' + state.userProfile.lastName,
  );
  const travelerId: number = useAppSelector(
    state => state.userProfile.travelerId,
  );

  const newPromotionsCount: number = useAppSelector(
    state => state.userProfile.promotionNotifications?.unreadCount || 0,
  );

  const handleClose = () => {
    dispatch(setProfileMenuState(false));
  };

  const handlePhotoEditingOpen = () => {
    dispatch(setPhotoEditingState(true));
    handleClose();
  };

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(authenticationActions.logoutUserAction());
    history.push(mapMenuIconsection(MenuIcons.Home));
  };

  const handleClick = (e: React.MouseEvent, menuIcon: MenuIcons) => {
    if (menuIcon === MenuIcons.Signout) {
      handleLogout(e);
    } else if (menuIcon === MenuIcons.AMNBadge) {
      toggleDrawer(e, true);
    } else {
      dispatch(setActivePage(menuIcon));
      history.push(mapMenuIconsection(menuIcon));
    }
    handleClose();
  };

  return (
    <>
      <Box>
        <Menu
          role="menu"
          aria-label="profile-menu"
          PaperProps={
            matchesMinWidth1024
              ? StylesMenuPaperProps
              : StylesMobileMenuPaperProps
          }
          anchorOrigin={{
            vertical: matchesMinWidth1024 ? 40 : 53,
            horizontal: 'center',
          }}
          sx={matchesMinWidth1024 ? StylesProfileMenu : StylesProfileMenuMobile}
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
            style: matchesMinWidth1024
              ? StylesProfileMenuInner
              : StylesProfileMenuInnerMobile,
          }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <Box
            key={'Profile Row'}
            tabIndex={0}
            sx={matchesMinWidth1024 ? StylesProfileRow : StylesProfileRowMobile}
          >
            <Box sx={StylesProfileRowImageWrapper}>
              <ProfileAvatar
                image={user?.profileImage?.url}
                size={80}
                onClick={handlePhotoEditingOpen}
                border="none"
                placeholderVariant={AvatarTextVariant.FIRST_ONLY}
                userName={user?.firstName || 'User'}
              />
            </Box>
            <Box sx={StylesUserInfoContainer}>
              <ThemeProvider theme={theme}>
                <Typography variant="h4">{userName}</Typography>
                <Box sx={StylesTravelerIdWrapper}>
                  <Typography variant="body2">{'Traveler ID: '}</Typography>
                  <Typography variant="caption">&nbsp;{travelerId}</Typography>
                </Box>
              </ThemeProvider>
            </Box>
          </Box>
          {icons.map(icon => (
            <MenuItem
              tabIndex={0}
              key={icon.name}
              sx={
                matchesMinWidth1024 ? StylesMenuOption : StylesMenuOptionMobile
              }
              onClick={e => handleClick(e, icon.name)}
              data-testid="menu-item"
            >
              <ListItemIcon
                key={icon.name}
                style={{
                  transform: icon.transform,
                }}
                sx={StylesListItem}
              >
                {icon.icon}
              </ListItemIcon>
              <Box sx={StyleMenuOptionText}>
                <ThemeProvider theme={theme}>
                  <Typography variant="body1" component="span">
                    {icon.name}
                  </Typography>
                </ThemeProvider>
                {icon.name === MenuIcons.Promotions && (
                  <Badge
                    badgeContent={newPromotionsCount}
                    color="error"
                    sx={{ paddingLeft: '20px' }}
                    invisible={userStatusIsLead || newPromotionsCount === 0}
                  />
                )}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default ProfileMenu;
