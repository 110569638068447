import { createTheme } from '@mui/material';
import { theme } from 'styles/theme';

const StylesDivider = {
  width: '35%',
  margin: '6px 6px 6px 27px',
};

const StylesHoverDivider = {
  ...StylesDivider,
  margin: '6px auto',
  width: '78%',
};

const StylesScrollBar = {
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '45%',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.system.navBorder,
    borderRight: '2px solid',
    borderRightColor: theme.palette.system.white,
    backgroundClip: 'padding-box',
    borderRadius: '25px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    margin: '20px',
    marginBottom: '80px',
  },
};

const StylesDrawerContainer = {
  padding: 0,
  width: '100%',
  position: 'relative',
  height: '100%',
  overflowY: 'scroll',
  ...StylesScrollBar,
};

const StylesHoverDrawerContainer = {
  position: 'absolute',
  left: '0',
  padding: 0,
  width: '210px',
  height: '100%',
  backgroundColor: theme.palette.system.white as string,
  borderTop: '1px solid',
  borderTopColor: theme.palette.system.navBorder as string,
  borderRight: '1px solid',
  borderRightColor: theme.palette.system.navBorder as string,
  ...StylesScrollBar,
};

const StylesListItem = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: '47px',
  height: '32px',
  padding: '0px',
  paddingLeft: '28px',
  marginTop: '7px',
  marginBottom: '7px',
  minWidth: 'initial',
};

const StylesHoverListItem = {
  ...StylesListItem,
};

const StylesMenuIconText = {
  paddingLeft: '14px',
  marginTop: '7px',
  marginBottom: '7px',
};

const StylesMenuIconTextHoverLastRow = {
  ...StylesMenuIconText,
};

const StylesMenuIconRow = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ' :hover ': {
    backgroundColor: theme.palette.system.backgroundGray,
  },
  height: '42px',
};

const StylesMenuIconRowActive = {
  ...StylesMenuIconRow,
};

const StylesMenuIconLastRow = {
  ...StylesMenuIconRow,
};

const StylesMenuIconHoverLastRow = {
  ...StylesMenuIconRow,
  ...StylesMenuIconLastRow,
  height: 'auto',
  paddingRight: '20px',
};

const StylesMenuIconHoverLastRowActive = {
  ...StylesMenuIconHoverLastRow,
  left: '0px',
  borderLeft: '3px solid',
  borderColor: theme.palette.system.skyBlue,
  paddingRight: '4px',
};

const StylesDrawerList = {
  padding: '0px',
  paddingTop: '8px',
};

const StylesHoverDrawerList = {
  ...StylesDrawerList,
};

const StylesMenuSectionText = {
  paddingBottom: '6px',
  paddingLeft: '23px',
};

const StylesActiveLeftBorder = {
  height: '36px',
  position: 'absolute',
  left: '6px',
  borderLeft: '4px solid',
  borderColor: theme.palette.system.skyBlue as string,
  borderRadius: '4px',
};

const StylesActiveLeftBorderHeight2Lines = {
  ...StylesActiveLeftBorder,
  height: '56px',
};

const StylesActiveLeftBorderHeight3Lines = {
  ...StylesActiveLeftBorder,
  height: '76px',
};

const commonFontProperties = {
  fontSize: 14,
  fontFamily: 'Open Sans, sans-serif',
};

let navMenuTheme = createTheme({
  typography: {
    body1: {
      ...commonFontProperties,
      fontWeight: 500,
      color: theme.palette.system.midnightBlue,
    },
    body2: {
      ...commonFontProperties,
      color: theme.palette.system.coolGray,
    },
  },
});

let activeTheme = createTheme({
  typography: {
    body1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Open Sans, sans-serif',
      color: theme.palette.system.skyBlue,
    },
  },
});

export {
  activeTheme,
  navMenuTheme as theme,
  StylesMenuIconRow,
  StylesMenuIconRowActive,
  StylesDrawerList,
  StylesHoverDrawerList,
  StylesMenuIconText,
  StylesMenuIconTextHoverLastRow,
  StylesActiveLeftBorder,
  // StylesActiveLeftBorderHeight1HalfLines,
  StylesActiveLeftBorderHeight2Lines,
  StylesActiveLeftBorderHeight3Lines,
  StylesMenuSectionText,
  StylesMenuIconLastRow,
  StylesMenuIconHoverLastRow,
  StylesMenuIconHoverLastRowActive,
  StylesDivider,
  StylesHoverDivider,
  StylesDrawerContainer,
  StylesHoverDrawerContainer,
  StylesListItem,
  StylesHoverListItem,
};
