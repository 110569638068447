export const actionType = {
  actionItem: 'actionItem',
  docusign: 'docusign',
  survey: 'survey',
  skillsChecklist: 'skillsChecklist',
  missingInformation: 'missingInformation',
};

export enum actionItemTypeId {
  BackgroundCheckAuthorization = 1,
  HealthcareLicensure = 2,
  NursingCertifications = 3,
  StateDriversLicense = 4,
  Covid19VaccinationCard = 5,
  HandbookAcknowledgement = 6,
  MedicalRecordsReleaseStatement = 7,
  CovidSymptomScreeningForm = 8,
  OnlineKnowledgeTesting = 9,
  CovidVaccinationTrainingModules = 10,
  StrikeBackgroundCheckInitialization = 11,
  StrikePhysicalExam = 12,
  StrikeUploadInfluenzaVaccination = 13,
  StrikeUploadMmrvVaccination = 14,
  StrikeUploadTuberculosisTest = 15,
  StrikeUploadTdapVaccination = 16,
  StrikeUploadFitTest = 17,
  StrikeUploadHealthcareLicensure = 18,
  StrikeUploadBlsAclsPalsOrNrp = 19,
  StrikeConfirmation = 21,
  StrikeWiselyPayCardNonExistingPayCardHolder = 20,
  StrikeWiselyPayCardExistingPayCardHolder = 20,
  CovidReligiousAccommodationRequest = 24,
  VirtualInterview = 25,
  Survey = 26,
  Docusign = 27,
  SkillsChecklist = 28,
  UpdateEmergencyContact = 29,
  UpdateCurrentAddress = 30,
  CovidBoosterEligible = 31,
  UpdateJobPreferences = 32,
  ConfirmyourTravelItinerary = 33,
  SkillsChecklistUpdateRequest = 34,
  StrikeEducationUpdateRequest = 35,
  WorkHistoryExperienceUpdateRequest = 36,
  WorkHistoryGapUpdate = 37,
  PayTransparencyDataCollection = 38,
  LicenseUpdate = 39,
  CertificationsUpdate = 40,
  ContractReadyToSign = 41,
  OngoingStrikeApply = 46,
  SocialSecurityNumber = 50,
  SocialSecurityNumberUpdate = 51,
  DateOfBirth = 52,
  DateOfBirthUpdate = 53,
  SkillsChecklistPreQualificationUpdate = 54,
  LicensePreQualificationUpdate = 55,
  EducationPreQualificationUpdate = 56,
  WorkHistoryExperiencePreQualificationUpdate = 57,
  WorkHistoryGapPreQualificationUpdate = 58,
  ReferencePreQualificationUpdate = 59,
  ExtendedWorkHistory = 60,
  MissingEducationInformation = 61,
  ArbitrationAgreement = 62,
}
