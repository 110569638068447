import React, { FC, Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import lookup from 'country-code-lookup';

import Grid from '@mui/material/Grid';

import { ReactHookFormLocationField } from 'components/common/LocationSearch/ReactHookFormLocationField';
import {
  AddressComponentCompoundTypes,
  AddressComponentTypes,
} from 'enums/googleAddressComponents';
import { SearchTypes } from 'enums/googleSearchTypeEnum';
import ICountry from 'interfaces/Common/ICountry';
import { IAddressProps } from 'interfaces/Props/IAddressProps';
import { RootState } from 'store/configureStore';
import { CustomAutocomplete } from '../Dropdown/CustomAutocomplete';
import {
  defaultCountry,
  defaultCountryEmpty,
} from 'interfaces/Props/Default/defaultCountry';

const Address: FC<IAddressProps> = ({
  nestedName,
  isDefaultCountryNotRequired,
}) => {
  let countryName = nestedName ? `${nestedName}.country` : 'country';
  let address1Name = nestedName ? `${nestedName}.address1` : 'address1';
  let cityName = nestedName ? `${nestedName}.city` : 'city';
  let stateName = nestedName ? `${nestedName}.state` : 'state';
  let zipCodeName = nestedName ? `${nestedName}.zipCode` : 'zipCode';
  let iso2CountryCodeName = nestedName
    ? `${nestedName}.iso2CountryCode`
    : 'iso2CountryCode';

  const mycountries: ICountry[] = useSelector((state: RootState) => {
    return state.lookup.countries;
  });
  const { setValue, watch, clearErrors } = useFormContext();
  const formData = watch();
  const defaultCountryValue = React.useMemo(() => {
    const selectedCountryCode = formData[nestedName]?.country;

    return selectedCountryCode && mycountries.length > 0
      ? mycountries.find(val => val.countryCode === selectedCountryCode)
      : isDefaultCountryNotRequired
      ? defaultCountryEmpty
      : defaultCountry;
  }, [formData, mycountries, nestedName]);

  return (
    <Fragment key={nestedName}>
      {defaultCountryValue && (
        <Grid item xs={12} mb={2}>
          <CustomAutocomplete
            label="Country"
            freeSolo={false}
            searchIcon={false}
            options={mycountries}
            optionLabel="countryName"
            name={countryName}
            value={formData?.[countryName]}
            valueLabel="countryCode"
            defaultValue={defaultCountryValue}
            onChange={val => {
              let countryCode = (val || {})['countryCode'];
              setValue(countryName, countryCode);
              clearErrors(countryName);

              const country = countryCode ? lookup.byIso(countryCode) : '';
              if (country) {
                setValue(iso2CountryCodeName, country?.iso2);
              }
              setValue(cityName, '');
              setValue(stateName, '');
              setValue(zipCodeName, '');
              setValue(address1Name, '');
            }}
            maxListHeight="11.5rem"
          />
        </Grid>
      )}

      <Grid item xs={12} mb={2}>
        <ReactHookFormLocationField
          type={SearchTypes.ADDRESS}
          fieldMap={{
            [stateName]: {
              type: AddressComponentTypes.STATE,
              useShortName: true,
            },
            [zipCodeName]: AddressComponentTypes.POSTAL_CODE,
            [cityName]: AddressComponentTypes.CITY,
          }}
          name={address1Name}
          label="Street Address"
          addressType={AddressComponentCompoundTypes.ADDRESS}
          hideDropdown
          hideIcon
          country={watch(iso2CountryCodeName)}
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <ReactHookFormLocationField
          type={SearchTypes.CITY}
          fieldMap={{
            [stateName]: {
              type: AddressComponentTypes.STATE,
              useShortName: true,
            },
            [zipCodeName]: AddressComponentTypes.POSTAL_CODE,
          }}
          name={cityName}
          label="City"
          addressType={AddressComponentTypes.CITY}
          hideDropdown
          hideIcon={false}
          country={watch(iso2CountryCodeName)}
        />
      </Grid>
    </Fragment>
  );
};

export default Address;
