import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/configureStore';

const profileDomain = (state: RootState) => state.userProfile;
const authDomain = (state: RootState) => state.auth;
export const userIdSelector = (state: RootState) => state.auth.userId;

export const authenticationSelector = createSelector(
  [profileDomain, authDomain],
  (profState, authState) =>
    !!profState.hasAcceptedTermsAndConditions && authState.isLoggedIn,
);
