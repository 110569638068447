import { IAddEducation } from 'interfaces/Education/IAddEducation';
import { IEditEducation } from 'interfaces/Education/IEditEducation';
import { ITravelerApplicationStatus } from 'interfaces/User/ITravelerApplicationStatus';
import { IUserEmailRequest } from 'interfaces/User/UserEmail/IUserEmailRequest';
import { IUserProfileRequest } from 'interfaces/User/UserProfile/IUserProfileRequest';
import { IUserVerification } from 'interfaces/User/UserVerification/IUserVerification';
import { IEditWorkHistory } from 'interfaces/WorkHistory/IEditWorkHistory';
import { CreateUserRequest, CreateUserResponse } from 'types/CreateUserRequest';
import { SuccessResponse } from 'types/SuccessResponse';
import {
  IAutoSubmitPreferencesRequest,
  ILocationSearchResponse,
  SearchLocationRequest,
  UserPreference,
  UserPreferenceRequest,
} from 'types/UserPreference';
import { IUserProfilePdfRequest } from 'types/UserProfilePdfRequest';
import { http } from '../BaseApi';

/**
 * Method to check if user exists
 *
 * @param userName - selected user name
 */
export const getUserDetail = async (userName: string): Promise<any> => {
  const postRequestBody = { emailAddress: userName };
  return await http.post<IUserEmailRequest>(
    '/auth/login/exists',
    postRequestBody,
  );
};

/**
 * Method to validate newly created user code
 *
 * @param userDetail - request body with email address and user code
 */
export const validateUserCode = async (
  userDetail: IUserEmailRequest,
): Promise<IUserVerification> =>
  await http.put<IUserEmailRequest>('auth/user/validatecode', userDetail);

/**
 * Create new user API
 * @param newUser Create New User model
 * @returns return true is sucess or Failed respone
 */
const createUser = async (
  newUser: CreateUserRequest,
): Promise<CreateUserResponse> => {
  return await http.post<CreateUserRequest>('/auth/v2/user', newUser);
};

const getUserProfile = async (userId: string): Promise<any> => {
  return await http.get(`/userprofile/${userId}`);
};

const getWebTravelerApplicationStatusService = async (
  userId: string,
): Promise<any> => {
  return await http.get(`/user/${userId}/traveler-app-status`);
};

export const generateUserProfilePdf = async (
  request: IUserProfilePdfRequest,
  userId: string,
): Promise<any> => {
  // We still need to pass the socialLinks object otherwise the API will return an error
  const tempRequest = {
    ...request,
    socialLinks: {
      linkedIn: false,
      facebook: false,
      instagram: false,
      twitter: false,
      tiktok: false,
    },
  };
  return await http.post(`/userprofile/${userId}/pdf`, tempRequest);
};

const updateUserProfile = async (
  userData: IUserProfileRequest,
): Promise<string> => {
  return await http.patch(`v2/userprofile/${userData.userId}`, userData);
};

const editUserEducation = async (
  userId: string,
  editEducation: IEditEducation,
): Promise<SuccessResponse> => {
  return await http.put<IEditEducation>(
    `/userprofile/${userId}/education`,
    editEducation,
  );
};

const postUserProfileImage = async (
  image: string,
  userId: string,
): Promise<string> => {
  return await http.post<string>(`/userprofile/${userId}/image`, image);
};

const deleteUserProfileImage = async (userId: string): Promise<string> => {
  return await http.delete<string>(`/userprofile/${userId}/image`);
};

const addUserEducation = async (
  userId: string,
  addEducation: IAddEducation,
): Promise<SuccessResponse> => {
  return await http.post<IAddEducation>(
    `/userprofile/${userId}/education`,
    addEducation,
  );
};

const editUserWorkHistory = async (
  userId: string,
  editWorkHistory: IEditWorkHistory,
): Promise<SuccessResponse> => {
  return await http.put<IEditWorkHistory>(
    `/userprofile/${userId}/employment-history`,
    editWorkHistory,
  );
};

const fetchUserPreference = async (userId: string): Promise<UserPreference> => {
  return await http.get(`/userpreferences/${userId}`);
};

const updateUserPreference = async (
  userId: string,
  payload: UserPreferenceRequest,
): Promise<string> => {
  return await http.put(`/userpreferences/${userId}`, payload);
};

const updateAutoSubmitPreferences = async (
  userid: string,
  payload: IAutoSubmitPreferencesRequest,
): Promise<string> => {
  return await http.put(`/userpreferences/${userid}/auto-submit`, payload);
};

const updatePreferredRecruiter = async (
  userId: string,
  recruiterId: number,
): Promise<string> => {
  const payload = {
    preferredRecruiter: recruiterId,
  };
  return await http.put(`/userpreferences/${userId}/recruiters`, payload);
};

const fetchSearchLocation = async (
  userId: string,
  limit: number = 20,
  payload: SearchLocationRequest,
): Promise<ILocationSearchResponse[]> => {
  return await http.post(
    `/userpreferences/${userId}/location?limit=${limit}`,
    payload,
  );
};

const fetchSearchAutoSubmitLocation = async (
  userId: string,
  limit: number = 20,
  payload: SearchLocationRequest,
): Promise<ILocationSearchResponse[]> => {
  return await http.post(
    `/userpreferences/${userId}/auto-submit-location?limit=${limit}`,
    payload,
  );
};

/**
 * Method to update welcome banner status as true
 * @param userId
 */
const updateWelcomeBannerStatus = async (
  userId: number,
): Promise<{
  isUpdated: boolean;
}> => {
  return await http.patch(`/userprofile/${userId}/welcomeBanner`, {
    hasSeenWelcomeBanner: true,
  });
};

const updatePayTransparency = async (
  userId: number,
  payTransparencyRequest: { gender: string; ethnicityTypeId: number },
): Promise<{ isUpdated: boolean }> => {
  return await http.patch(
    `/userprofile/${userId}/pay-transparency`,
    payTransparencyRequest,
  );
};

export {
  addUserEducation,
  createUser,
  deleteUserProfileImage,
  editUserEducation,
  editUserWorkHistory,
  fetchSearchAutoSubmitLocation,
  fetchSearchLocation,
  fetchUserPreference,
  getUserProfile,
  getWebTravelerApplicationStatusService,
  postUserProfileImage,
  updateAutoSubmitPreferences,
  updatePayTransparency,
  updatePreferredRecruiter,
  updateUserPreference,
  updateUserProfile,
  updateWelcomeBannerStatus,
};
