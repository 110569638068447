import { SxProps } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AlertIcon from 'assets/icons/Notifications/AlertIcon';
import { AppLink } from 'components/common/Link/Link';
import { CredentialStatusOptions } from 'constants/assignmentLinkStackOptions';
import React, { FC } from 'react';
import { theme } from 'styles/theme';

export interface IAssignmentLinkProps {
  id: string;
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  href?: string;
  underline: 'none' | 'hover' | 'always';
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  justifyContent?: JustifyContent;
  fontSize?: string;
  amount?: number;
  sx?: SxProps;
  showAlert?: boolean;
  credentialStatus?: string;
}
type JustifyContent =
  | 'flex-start'
  | 'left'
  | 'normal'
  | 'right'
  | 'center'
  | (string & {});

export const AssignmentLink: FC<IAssignmentLinkProps> = ({
  id,
  amount,
  onClick,
  href,
  children,
  underline,
  rightIcon,
  leftIcon,
  justifyContent = 'center',
  fontSize = '14px',
  sx,
  showAlert = false,
  credentialStatus = '',
}) => (
  <>
    <Grid
      container
      item
      id={id}
      sx={
        sx ?? {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
            color: 'system.skyBlue',
          },
        }
      }
      onClick={onClick}
    >
      <Grid
        item
        xs={true}
        sx={
          sx ?? {
            display: 'flex',
            justifyContent,
            alignItems: 'center',
            cursor: 'pointer',
            minHeight: '23px',
          }
        }
      >
        {leftIcon ?? null}
        <AppLink
          component="button"
          href={href}
          underline={underline}
          linkStyles={{
            marginLeft: leftIcon ? theme.spacing(1) : theme.spacing(0),
            marginRight: rightIcon ? theme.spacing(1) : theme.spacing(0),
            fontWeight: 400,
            lineHeight: '20px',
            fontSize: fontSize,
          }}
          id="assignment-link"
        >
          {children}

          {typeof amount !== 'undefined' && amount > 0 && (
            <Box
              sx={{
                display: 'inline-block',
                px: 1,
              }}
            >
              <Badge
                badgeContent={amount}
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    fontWeight: 700,
                  },
                  marginLeft: '12px',
                }}
              />
            </Box>
          )}

          {!!credentialStatus && !amount && (
            <Box
              sx={{
                display: 'inline-block',
                px: 1,
              }}
            >
              <Badge
                badgeContent={credentialStatus}
                sx={{
                  '& .MuiBadge-badge': {
                    fontWeight: 600,
                    fontSize: '14px',
                    position: 'relative',
                    transform: 'none',
                    backgroundColor:
                      credentialStatus === CredentialStatusOptions.DONE
                        ? theme.palette.system.grassGreen
                        : theme.palette.system.coolGray,
                    color: theme.palette.system.white,
                  },
                }}
              />
            </Box>
          )}

          {typeof showAlert !== 'undefined' && showAlert && (
            <Box
              sx={{
                display: 'inline-block',
                verticalAlign: 'text-bottom',
                px: 1,
              }}
            >
              {showAlert &&
                AlertIcon({
                  color: theme.palette.system.error,
                  height: '17',
                  width: '17',
                })}
            </Box>
          )}
        </AppLink>
      </Grid>
      <Grid item>{rightIcon ?? null}</Grid>
    </Grid>
  </>
);
