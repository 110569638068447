import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ActionRequiredIcon from 'assets/icons/Alert/ActionRequiredIcon';
import wiselyCard from 'assets/images/WiselyCard/WiselyCard.png';
import { ActionDialogBody } from 'components/ActionItem/ActionDialogBody';
import {
  actionDialogContent,
  getLinkTypeId,
  returnClearanceItemMessageFromLink,
} from 'components/ActionItem/helper';
import { JobPreference } from 'components/Jobs/JobSearch/JobPreference';
import { PersonalInformation } from 'components/PersonalInformation/PersonalInformation';
import { TOAST_MESSAGE } from 'constants/helperText';
import { IActionItemMenuOptions } from 'enums/actionItemMenuOptions';
import { actionItemTypeId } from 'enums/actionType';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { LinkType } from 'enums/linkConfig';
import { MenuIcons } from 'enums/menuIcons';
import { filterRedirectedOpenRequirement } from 'helpers/credentialCenterHelper';
import { openInNewTab } from 'helpers/openInNewTab';
import { IActionItems } from 'interfaces/ActionItem/IActionItems';
import {
  ActionRequiredDialogProps,
  IDialogButton,
} from 'interfaces/Props/IDialogProps';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { openPDF } from 'store/slices/domain/domainSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import mapMenuIconSection from 'utils/mapMenuIconNameSection';
import ActionRequiredDialog from '../Dialog/ActionRequiredDialog';
import { DefaultDialog } from '../Dialog/DefaultDialog';
import { TaskActionItemDrawer } from './TaskActionItemDrawer';
import { TaskCredentialActionDrawer } from './TaskCredentialActionDrawer';
import { IClearanceItemProps } from 'components/ClearanceItem/ClearanceItem';
import { TaskCenterCategoryTypeId, userCredentials } from 'enums/credential';
import { AppRouteNames } from 'routes/appRouteNames';

export const TaskActionsManager = ({
  selectedPlacementId,
  setSelectedPlacementId,
  selectedOpenTask,
  setSelectedOpenTask,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectedItem = useRef('');

  const user = useAppSelector(state => {
    if (state.userProfile) {
      return state.userProfile;
    }
  });
  const skillsChecklistViewUrl = useAppSelector(state => {
    return state.auth.versionCheck?.skillsChecklistViewUrl;
  });
  const openTasks = useAppSelector(state => state.credential.openRequirements);
  const selectedActionMenuItem = useAppSelector(
    state => state.credential?.selectedActionMenuItem,
  );

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('default');
  const [dismissal, setDismissal] = useState<string>('');
  const [personalInformation, setPersonalInformation] = useState(false);
  const [personalInformationScrollInto, setPersonalInformationScrollInto] =
    useState<string>();
  const [jobPreferences, setJobPreferences] = useState(false);
  const [actionRequiredDialog, setActionRequiredDialog] = useState<
    ActionRequiredDialogProps | undefined
  >(undefined);
  const [optInSelection, setOptInSelection] = useState<any>(undefined);
  const [isSsnActionItem, setIsSsnActionItem] = useState(false);
  const [clearanceItemProps, setClearanceItemProps] =
    useState<IClearanceItemProps>();

  const dispatchActionItemDrawer = action =>
    dispatch(credentialActions.setActionItemDrawer(action));

  const dispatchCredentialDrawer = action =>
    dispatch(credentialActions.setTaskCredentialActionDrawer(action));

  const dismissAfterSubmit = () =>
    dispatch(
      actionItemActions.removeActionItem({
        dismissals: { dismissals: [selectedOpenTask?.id] },
      }),
    );

  const actualOpenTaskItem = useCallback(
    id =>
      openTasks.find(task => {
        if (task.actionItem && task.actionItem.id === id) return task;
        else if (task.survey && task.survey.id === id) return task;
        else if (task.contract && task.contract.id === id) return task;
        else if (task.skillsChecklist && task.skillsChecklist.id === id)
          return task;
        else if (
          task.assignmentCredential &&
          task.assignmentCredential.id === id
        )
          return task;
        else if (task.userCredential && task.userCredential.id === id)
          return task;
        return undefined;
      }),
    [openTasks],
  );

  const setOpenSnackbar = () =>
    dispatch(
      openAlert({
        variant: 'success',
        message: TOAST_MESSAGE.ActionItemRemoved,
        actionButton: {
          text: 'UNDO',
          variant: 'text',
          onAction: () => {
            selectedItem.current = '';
            setDismissal('');
            dispatch(credentialActions.setOpenRequirements(openTasks));
          },
        },
      }),
    );

  const handleClickOpen = (type: string) => {
    setOpen(true);
    setType(type);
  };
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    const newOpenTasks = openTasks.filter(task =>
      task?.actionItem
        ? task?.actionItem?.id !== selectedOpenTask.id
        : task?.survey
        ? task?.survey?.id !== selectedOpenTask.id
        : task?.contract
        ? task?.actionItem?.id !== selectedOpenTask.id
        : true,
    );
    dispatch(credentialActions.setOpenRequirements(newOpenTasks));
    setDismissal(selectedOpenTask.id);
    setOpenSnackbar();
    setOpen(false);
  };

  const togglePersonalInformation = open => {
    if (!open) {
      dispatch(actionItemActions.resetSelectedItem());
      setPersonalInformation(open);
    }
  };

  // Job Preference Drawer
  const toggleUpdateJobPreferences = (
    event: React.KeyboardEvent | React.MouseEvent | null,
    open: boolean,
  ) => {
    if (!open) {
      dispatch(actionItemActions.resetSelectedItem());
    }

    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setJobPreferences(open);
  };

  const getBody = (
    actionItemType,
    name?,
    expirationDate?: string,
    title?: string,
  ) => {
    return (
      <ActionDialogBody
        actionItemType={actionItemType}
        name={name}
        title={title}
        expirationDate={expirationDate}
        onBodyActionClick={e => {
          setOptInSelection(e);
          setActionRequiredDialog(prevState => {
            let dialogProps = Object.assign({}, prevState);
            if (dialogProps && dialogProps.buttons)
              dialogProps.buttons[0].disabled = false;
            return {
              ...dialogProps,
            };
          });
        }}
      />
    );
  };

  const getUploadButton = () => {
    return {
      id: 'action-required-upload',
      text: 'Upload',
      onClick: () => {
        dispatchActionItemDrawer(DrawerContentNames.ADD_CREDENTIAL);
        setActionRequiredDialog(undefined);
      },
    };
  };

  const getFormButton = (text: string) => {
    return {
      id: 'action-required-declination',
      text: text,
      onClick: () => {
        showFormStackComponent();
        setActionRequiredDialog(undefined);
      },
    };
  };

  const getCancelButton = () => {
    return {
      id: 'action-required-cancel',
      text: 'Cancel',
      onClick: () => {
        setActionRequiredDialog(undefined);
      },
    };
  };

  const getWiselySubmitButton = (text: string) => {
    return {
      id: 'wisely-submit',
      text: text,
      disabled: text === 'Submit' ? true : false,
      onClick: () => {
        if (optInSelection !== undefined) {
          dispatch(
            actionItemActions.savePaycardInfo(optInSelection === 'true'),
          );
        } else {
          dispatch(actionItemActions.savePaycardInfo(true));
        }
        setActionRequiredDialog(undefined);
      },
    };
  };

  const getButtons = useCallback(
    (actionItemType, name?, title?) => {
      let buttons: IDialogButton[] = [];
      switch (actionItemType) {
        case actionItemTypeId.StrikeWiselyPayCardExistingPayCardHolder:
          if (name.includes('Non Existing') || title?.includes('Opt-in')) {
            buttons.push(getWiselySubmitButton('Submit'));
          } else {
            buttons.push(getWiselySubmitButton('Confirm'));
            buttons.push({
              id: 'wisely-opt-out',
              text: 'Opt-Out',
              onClick: () => {
                dispatch(actionItemActions.savePaycardInfo(false));
                setActionRequiredDialog(undefined);
              },
            });
          }
          break;
        default:
          buttons.push(getUploadButton());
          if (actionItemType === actionItemTypeId.StrikeUploadTuberculosisTest)
            buttons.push(getFormButton('Tuberculosis Questionnaire'));
          else if (actionItemType === actionItemTypeId.StrikeUploadFitTest) {
            buttons.push(getFormButton('Fit Test Questionnaire'));
          } else {
            buttons.push(getFormButton('Declination Form'));
          }
          break;
      }
      buttons.push(getCancelButton());
      return buttons;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionRequiredDialog],
  );

  const getActionRequiredDialogProps = useCallback(
    (
      actionItemType: actionItemTypeId,
      name?: string,
      expirationDate?: string,
      title?: string,
    ) => {
      let obj: ActionRequiredDialogProps = {} as ActionRequiredDialogProps;
      obj.open = true;
      if (
        actionItemType ===
        actionItemTypeId.StrikeWiselyPayCardExistingPayCardHolder
      ) {
        if (name?.includes('Non Existing') || title?.includes('Opt-in')) {
          obj.icon = <img src={wiselyCard} alt="wisely-card" />;
          obj.body = getBody(
            actionItemTypeId.StrikeWiselyPayCardNonExistingPayCardHolder,
            name,
            expirationDate,
            title,
          );
          obj.buttons = getButtons(
            actionItemTypeId.StrikeWiselyPayCardNonExistingPayCardHolder,
            name,
            title,
          );
          obj.type = 'Strike';
          obj.title = 'Wisely Pay Card - Opt-In/Opt-Out';
        } else {
          obj.icon = <img src={wiselyCard} alt="wisely-card" />;
          obj.body = getBody(
            actionItemTypeId.StrikeWiselyPayCardExistingPayCardHolder,
            name,
          );
          obj.buttons = getButtons(
            actionItemTypeId.StrikeWiselyPayCardExistingPayCardHolder,
            name,
          );
          obj.type = 'Strike';
          obj.title = 'Wisely Pay Card';
        }
      } else if (
        [
          actionItemTypeId.Covid19VaccinationCard,
          actionItemTypeId.StrikeUploadInfluenzaVaccination,
          actionItemTypeId.StrikeUploadTuberculosisTest,
          actionItemTypeId.StrikeUploadTdapVaccination,
          actionItemTypeId.StrikeUploadFitTest,
        ].indexOf(actionItemType) > -1
      ) {
        obj.icon = <ActionRequiredIcon height={'57px'} width={'65px'} />;
        obj.body = actionDialogContent.get(actionItemType);
        obj.buttons = getButtons(actionItemType, name);
        obj.title = 'Action Required';
      }
      setActionRequiredDialog(obj);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionRequiredDialog],
  );

  const showComponentForDl = (selectedItem: IActionItems) => {
    switch (selectedItem?.actionItemTypeId) {
      case actionItemTypeId.UpdateEmergencyContact:
        setIsSsnActionItem(false);
        setClearanceItemProps(undefined);
        setPersonalInformationScrollInto('emergency-contact');
        setPersonalInformation(true);
        break;
      case actionItemTypeId.DateOfBirth:
      case actionItemTypeId.DateOfBirthUpdate:
        setPersonalInformation(true);
        setIsSsnActionItem(false);
        if (selectedItem?.actionItem) {
          let clearanceMessageData = returnClearanceItemMessageFromLink(
            selectedItem.actionItem,
          );
          setClearanceItemProps({
            id: 'date-of-birth',
            header: clearanceMessageData.title,
            message: clearanceMessageData.description,
            sx: {
              position: 'absolute',
              left: '12px',
              top: '84px',
              right: '12px',
              zIndex: 1000,
              backgroundColor: 'system.white',
            },
          });
        }
        setPersonalInformationScrollInto('legal-information');
        break;
      case actionItemTypeId.SocialSecurityNumber:
      case actionItemTypeId.SocialSecurityNumberUpdate:
        setPersonalInformationScrollInto(undefined);
        setPersonalInformation(true);
        if (selectedItem?.actionItem) {
          let clearanceMessageData = returnClearanceItemMessageFromLink(
            selectedItem.actionItem,
          );
          setClearanceItemProps({
            id: 'social-security-number',
            header: clearanceMessageData.title,
            message: clearanceMessageData.description,
            sx: {
              position: 'absolute',
              left: '12px',
              top: '84px',
              right: '12px',
              zIndex: 1000,
              backgroundColor: 'system.white',
            },
          });
        }
        if (
          selectedItem?.actionItem?.actionItemTypeId ===
            actionItemTypeId.SocialSecurityNumber ||
          selectedItem?.actionItem?.actionItemTypeId ===
            actionItemTypeId.SocialSecurityNumberUpdate
        ) {
          setIsSsnActionItem(true);
          setPersonalInformationScrollInto('legal-information');
        } else setIsSsnActionItem(false);
        break;
      case actionItemTypeId.UpdateCurrentAddress: {
        setClearanceItemProps(undefined);
        setPersonalInformation(true);
        setIsSsnActionItem(false);
        break;
      }
      case actionItemTypeId.StrikeWiselyPayCardExistingPayCardHolder:
      case actionItemTypeId.StrikeWiselyPayCardNonExistingPayCardHolder:
        let strikeDate = '';
        if (
          selectedItem?.actionItem?.title?.includes('Opt-in') &&
          selectedItem?.actionItem?.links
        ) {
          const splitUrl =
            selectedItem?.actionItem?.links[0]?.url?.split('startDate=');
          strikeDate = splitUrl[1]?.split('&&')[0];
        }
        getActionRequiredDialogProps(
          actionItemTypeId.StrikeWiselyPayCardNonExistingPayCardHolder,
          selectedItem?.actionItem?.actionItemType,
          strikeDate,
          selectedItem?.actionItem?.title,
        );
        break;
      case actionItemTypeId.UpdateJobPreferences: {
        setJobPreferences(true);
        break;
      }
      case actionItemTypeId.ConfirmyourTravelItinerary: {
        setSelectedPlacementId(selectedItem?.actionItem?.placementId);
        dispatchActionItemDrawer(DrawerContentNames.SHOW_TRAVEL_DETAILS);
        break;
      }
      case actionItemTypeId.PayTransparencyDataCollection: {
        dispatchActionItemDrawer(DrawerContentNames.PAY_TRANSPARENCY);
        break;
      }
      case actionItemTypeId.LicenseUpdate:
      case actionItemTypeId.LicensePreQualificationUpdate:
        dispatch(
          actionItemActions.setSelectedClearanceItem(selectedItem.actionItem),
        );
        let dlUrl: string = selectedItem?.actionItem?.links
          ? selectedItem?.actionItem?.links[0]?.url
          : '';
        const params = new URLSearchParams(dlUrl.split('?')[1] || '');
        let licenseType = params.get('type') ?? null;
        let disciplineId = params.get('disciplineId') ?? '';
        dispatch(
          credentialActions.setLicenseTypeAndDiscipline({
            licenseType: licenseType === null ? null : +licenseType,
            disciplineId: +disciplineId,
          }),
        );
        dispatchCredentialDrawer(DrawerContentNames.LICENSE_VERIFICATION);
        break;
      case actionItemTypeId.ContractReadyToSign: {
        setSelectedPlacementId(selectedItem?.contract?.placementId);
        dispatch(
          credentialActions.setContractDetailsId(selectedItem?.contract?.id),
        );
        break;
      }
      case actionItemTypeId.ArbitrationAgreement:
        dispatchActionItemDrawer(DrawerContentNames.ARBITRATION_AGREEMENT);
        break;
      default:
        if (selectedItem?.actionItem?.links) {
          const params = new URLSearchParams(
            selectedItem?.actionItem?.links[0].url.split('?')[1] || '',
          );
          const tab = params.get('tab');
          const credentialId = params.get('id');
          const envelopeId = params.get('envelopeId');
          const licenseType = params.get('type') ?? null;
          const disciplineId = params.get('disciplineId') ?? null;
          const placementId = params.get('placementId');

          const deeplinkUrl: string = selectedItem?.actionItem?.links
            ? selectedItem?.actionItem?.links[0]?.url
            : '';

          if (tab === 'open') {
            history.push(
              `/${AppRouteNames.TASK_CENTER}?openRequirements=${true}`,
            );
          } else if (!!envelopeId) {
            history.push(
              `/${AppRouteNames.TASK_CENTER}?envelopeId=${envelopeId}`,
            );
          } else if (deeplinkUrl.includes('AddCredentialItem'))
            dispatchActionItemDrawer(DrawerContentNames.ADD_CREDENTIAL);
          else if (credentialId && !placementId) {
            history.push(
              `/${AppRouteNames.TASK_CENTER}?myDocumentItem=${credentialId}`,
            );
          } else if (deeplinkUrl.includes('TaskCenter')) {
            history.push(
              `/${AppRouteNames.TASK_CENTER}?credentialId=${credentialId}&placementId=${placementId}&credentialType=${TaskCenterCategoryTypeId.Credentials}`,
            );
          } else if (deeplinkUrl.includes('CredentialSubmissions')) {
            history.push(
              `/${AppRouteNames.TASK_CENTER}?myDocumentCategory=${userCredentials.Submissions}`,
            );
          } else if (deeplinkUrl.includes('VaccinationRecord')) {
            dispatchCredentialDrawer(
              DrawerContentNames.CREDENTIAL_COVID_VACCINE_RECORD,
            );
          } else if (licenseType || disciplineId) {
            dispatch(
              credentialActions.setLicenseTypeAndDiscipline({
                licenseType: licenseType === null ? null : +licenseType,
                disciplineId: disciplineId === null ? null : +disciplineId,
              }),
            );
            dispatch(actionItemActions.setSelectedClearanceItem(undefined));
            dispatchCredentialDrawer(DrawerContentNames.LICENSE_VERIFICATION);
          } else history.push(`/${AppRouteNames.TASK_CENTER}`);
        }
    }
  };

  const performWebAction = selectedItem => {
    let url = '';
    switch (selectedItem?.actionItemTypeId) {
      case actionItemTypeId.Survey:
        url = selectedItem?.actionItem?.links?.[0]?.url;
        break;
      case actionItemTypeId.Docusign:
        dispatch(actionItemActions.getDocusignUrl());
        break;
      case actionItemTypeId.SkillsChecklist:
        let appendUrl = '';
        if (selectedItem?.skillsChecklist?.latestCompletedId)
          appendUrl = `sk/hp/${selectedItem?.skillsChecklist?.latestCompletedId}`;
        else if (selectedItem?.skillsChecklist?.id)
          appendUrl = `sk/${selectedItem?.skillsChecklist?.id}`;
        url = skillsChecklistViewUrl + appendUrl;
        break;
      case actionItemTypeId.VirtualInterview:
        url = selectedItem?.actionItem?.links[0]?.url?.split('link=').pop();
        dismissAfterSubmit();
        break;
      default:
        url = selectedItem?.actionItem?.links[0]?.url;
        break;
    }
    openInNewTab(url);
    dispatch(actionItemActions.resetSelectedItem());
  };

  const performAppNavigation = selectedItem => {
    dispatch(
      actionItemActions.setSelectedClearanceItem(selectedItem.actionItem),
    );
    switch (selectedItem?.actionItemTypeId) {
      case actionItemTypeId.SkillsChecklistUpdateRequest:
      case actionItemTypeId.SkillsChecklistPreQualificationUpdate:
        dispatch(setActivePage(MenuIcons.SkillsChecklist));
        history.push(mapMenuIconSection(MenuIcons.SkillsChecklist));
        break;
      case actionItemTypeId.StrikeEducationUpdateRequest:
      case actionItemTypeId.EducationPreQualificationUpdate:
      case actionItemTypeId.WorkHistoryExperienceUpdateRequest:
      case actionItemTypeId.WorkHistoryExperiencePreQualificationUpdate:
      case actionItemTypeId.WorkHistoryGapUpdate:
      case actionItemTypeId.WorkHistoryGapPreQualificationUpdate:
      case actionItemTypeId.ReferencePreQualificationUpdate:
      case actionItemTypeId.ExtendedWorkHistory:
      case actionItemTypeId.MissingEducationInformation:
        dispatch(setActivePage(MenuIcons.Profile));
        history.push(mapMenuIconSection(MenuIcons.Profile));
        break;
    }
  };

  const showFormStackComponent = (selectedItem?: IActionItems) => {
    if (selectedItem) {
      switch (selectedItem?.actionItemTypeId) {
        case actionItemTypeId.OngoingStrikeApply:
          dispatch(
            actionItemActions.setSelectedClearanceItem(selectedItem.actionItem),
          );
          setSelectedPlacementId(selectedItem?.actionItem?.placementId);
          dispatchActionItemDrawer(DrawerContentNames.ONGOING_STRIKE_APPLY);
          break;
        default:
          dispatchActionItemDrawer(DrawerContentNames.FORMSTACK);
          break;
      }
    }
  };

  const performPdfAction = (selectedItem: IActionItems) =>
    dispatch(openPDF((selectedItem?.actionItem?.links || [])[0]?.url));

  const showComponentForMulti = selectedItem => {
    getActionRequiredDialogProps(
      selectedItem?.actionItem?.actionItemTypeId,
      selectedItem?.actionItem?.actionItemType,
    );
  };

  const performActionsForSelectedItem = selectedItem => {
    switch (getLinkTypeId(selectedItem)?.toString()) {
      case LinkType.MobileDeepLink:
        showComponentForDl(selectedItem);
        break;
      case LinkType.Formstack:
        showFormStackComponent(selectedItem);
        break;
      case LinkType.Multiple:
        showComponentForMulti(selectedItem);
        break;
      case LinkType.PDF:
        performPdfAction(selectedItem);
        break;
      case LinkType.Web:
        performWebAction(selectedItem);
        break;
      case LinkType.InAppLink:
        performAppNavigation(selectedItem);
        break;
      default:
        break;
    }
  };

  const onMenuClick = (menuOption: string, taskId) => {
    selectedItem.current = taskId;
    setSelectedOpenTask({ id: taskId });
    switch (IActionItemMenuOptions[menuOption]) {
      case IActionItemMenuOptions['Go to Item']:
        const newTask = actualOpenTaskItem(taskId);
        // currently LinkType.InAppLink are only qualification/clearance items
        // if this changes in the future, this check will need to be modified
        if (getLinkTypeId(newTask)?.toString() !== LinkType.InAppLink) {
          dispatch(
            actionItemActions.setSelectedItem({
              ...newTask,
              actionItemType: newTask?.actionItem?.actionItemType,
              actionItemTypeId: newTask?.actionItem?.actionItemTypeId,
            }),
          );
        }
        performActionsForSelectedItem(newTask);
        break;
      case IActionItemMenuOptions['Remove Item']:
        handleClickOpen('default');
        break;
      case IActionItemMenuOptions['Cancel']:
      default:
        setSelectedOpenTask(undefined);
        break;
    }
    dispatch(credentialActions.setSelectedActionMenuItem(undefined));
  };

  // This useEffect is used to handle the action item menu click
  // its listening to the selectedActionMenuItem redux state to perform the action
  // dispatch(credentialActions.setSelectedActionMenuItem({ menuItem, task }))
  useEffect(() => {
    if (
      !!selectedActionMenuItem &&
      !!selectedActionMenuItem.menuItem &&
      !!selectedActionMenuItem.taskId
    ) {
      onMenuClick(
        selectedActionMenuItem.menuItem,
        selectedActionMenuItem.taskId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionMenuItem]);

  useEffect(() => {
    if (!!dismissal) {
      setTimeout(() => {
        if (selectedItem.current !== '') {
          dispatch(
            actionItemActions.removeActionItem({
              dismissals: { dismissals: [dismissal] },
            }),
          );
          selectedItem.current = '';
        }
      }, 6000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissal]);

  return (
    <>
      <TaskActionItemDrawer
        selectedPlacementId={selectedPlacementId}
        setSelectedPlacementId={setSelectedPlacementId}
      />
      <TaskCredentialActionDrawer
        selectedOpenTask={selectedOpenTask}
        setSelectedOpenTask={setSelectedOpenTask}
      />
      {user && (
        <PersonalInformation
          open={personalInformation}
          setOpen={open => {
            togglePersonalInformation(open);
          }}
          afterSubmit={
            clearanceItemProps?.id === 'date-of-birth' ||
            clearanceItemProps?.id === 'social-security-number'
              ? undefined
              : dismissAfterSubmit
          }
          scrollInto={personalInformationScrollInto ?? undefined}
          isSsnActionItem={isSsnActionItem}
          clearanceItemProps={clearanceItemProps}
        />
      )}
      {jobPreferences && (
        <JobPreference
          open={jobPreferences}
          toggleDrawer={toggleUpdateJobPreferences}
        />
      )}
      {actionRequiredDialog && (
        <ActionRequiredDialog {...actionRequiredDialog} />
      )}
      <DefaultDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        type={type}
        title="Remove Item?"
        body="Confirm removal from list."
      />
    </>
  );
};
