import { IAssignmentSummary } from 'interfaces/Assignment/AssignmentSummary';
import { IReimbursementRequest } from 'interfaces/Reimbursement/IReimbursementForm';
import { http } from 'services/BaseApi';

export const getReimbursementService = async (
  userId: string,
  offset: number,
  limit: number,
): Promise<IAssignmentSummary> => {
  return await http.get<string>(
    `/reimbursement/${userId}?offset=${offset}&limit=${limit}`,
  );
};

export const postReimbursementRequest = async (
  userId: string,
  body: IReimbursementRequest,
): Promise<any> => {
  return await http.post<IReimbursementRequest>(
    `/v2/reimbursement/${userId}`,
    body,
  );
};

export const postReimbursementDocumentRequest = async (
  userId: string,
  body: any,
): Promise<any> => {
  return await http.postUpload<any>(`/reimbursement/${userId}/document`, body);
};

export const fetchMileageReimbursement = async (
  userId: string,
  requestBody,
): Promise<{ mileage: number }> => {
  return await http.post<any>(`reimbursement/${userId}/distance`, requestBody);
};
