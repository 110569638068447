import { http } from 'services/BaseApi';
import { SsoResponse } from 'types/SsoResponse';

export const conduentSso = async (userId: string): Promise<SsoResponse> => {
  return await http.get<string>(`/conduent/${userId}`);
};

export const kaiserSso = async (userId: string): Promise<SsoResponse> => {
  return await http.get<string>(`/peoplenet/${userId}`);
};
