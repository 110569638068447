export enum DrawerContentNames {
  // @TODO: alphabetize these
  ADD_EDUCATION = 'add-education',
  ADD_CREDENTIAL = 'add-credential',
  ADD_WORK_HISTORY = 'add-work-history',
  ASSIGNMENT_EXTENSION = 'assignment-extension',
  ASSIGNMENT_EXTENSION_YES = 'assignment-extension-yes',
  ASSIGNMENT_EXTENSION_NO = 'assignment-extension-no',
  ASSIGNMENT_EXTENSION_GOT_IT = 'assignment-extension-got-it',
  ASSIGNMENT_EXTENSION_NO_WORRIES = 'assignment-extension-no-worries',
  REQUEST_EDIT = 'request-edit',
  VIEW_EDUCATION = 'view-education',
  VIEW_REFERENCES = 'view-references',
  ADD_REFERENCE = 'add-reference',
  CLOSED_DRAWER = '',
  VIEW_CONTACTS = 'view-contacts',
  VIEW_CONTRACTS = 'view-contracts',
  CONTRACT_READY_TO_SIGN = 'contract-ready-to-sign',
  CONTRACT_SIGNED = 'contract-signed',
  ASSIGNMENT_MOBILE = 'assignment-mobile',
  ASSIGNMENT_MOBILE_SKELETAL = 'assignment-mobile-skeletal',
  ADD_TIMESHEET_APPROVER = 'add-timesheet-approver',
  EDIT_TIMESHEET_APPROVER = 'edit-timesheet-approver',
  ADD_TIMESHEET_ENTRY = 'add-timesheet-entry',
  EDIT_TIMESHEET_ENTRY = 'edit-timesheet-entry',
  TIMESHEET_MOBILE = 'timesheet-mobile',
  TIMESHEET_MOBILE_SKELETAL = 'timesheet-mobile-skeletal',
  EDIT_TIMESHEET_SHIFT_ENTRY = 'edit-timesheet-shift-entry',
  CREDENTIAL_COVID_VACCINE_RECORD = 'credential-covid-vaccine-record',
  ADD_IN_EDIT_TIMESHEET_ENTRY = 'add-in-edit-timesheet-entry',
  TRAVEL_ITEN = 'travel-iten',
  EVENT_RES = 'event-res',
  VIEW_QUESTIONS = 'questions',
  RATE_CONTACT = 'rate-contact',
  REFER_FRIEND = 'refer-friend',
  NON_PASSPORT_TIMESHEET_MOBILE_SKELETAL = 'non-passport-timesheet-mobile-skeletal',
  HELP = 'help',
  SUBMIT_SUPPORT_TICKET = 'submit-support-ticket',
  SHOW_TRAVEL_DETAILS = 'show-travel-details',
  CONFIRM_TRAVEL_DETAILS = 'confirm-travel-details',
  CREDENTIAL_CENTER_UPLOAD = 'credential-center-upload',
  CRED_DECLINATION_FORM = 'cred-declination-form',
  FORMSTACK = 'formstack',
  SKILLS_CHECKLIST = 'skills-checklist',
  LICENSE_VERIFICATION = 'license-verification',
  PAY_TRANSPARENCY = 'pay-transparency',
  LICENSE_UPDATE = 'license-update',
  CERTIFICATIONS_UPDATE = 'certifications-update',
  OPEN_NON_PASSPORT_TIMESHEET = 'open-non-passport-timesheet',
  QUALIFICATION_ITEMS = 'qualification-items',
  HELP_CENTER_BENEFITS = 'help-center-benefits',
  HELP_CENTER_HOUSING = 'help-center-housing',
  HELP_CENTER_FAQ = 'help-center-faq',
  SCHEDULE_SHIFTS = 'scheduled-shifts',
  HELP_CENTER_TIME_AND_PAY = 'help-center-time-and-pay',
  HELP_CENTER_GENERAL_SUPPORT = 'help-center-general-support',
  HELP_CENTER_CREDENTIALING = 'help-center-credentialing',
  HELP_CENTER_ASSIGNMENT_AND_CONTRACTS = 'help-center-assignment-and-contracts',
  CONSECUTIVE_WAVE_EXTENSION = 'consecutive-wave-extension',
  ONGOING_STRIKE_APPLY = 'ongoing-strike-apply',
  ADD_IDENTIFICATION = 'add-identification',
  SHOW_JOB_DETAILS = 'show-job-details',
  SELECT_PREFERRED_RECRUITER = 'select-preferred-recruiter',
  SHOW_ASSIGNMENT_STATUS_STEPPER = 'show-assignment-status-stepper',
  UPLOADED_DOCUMENTS = 'uploaded-documents',
  JOB_SEARCH_FILTERS = 'job-search-filters',
  SCREEN_SHARE = 'screen-share',
  ARBITRATION_AGREEMENT = 'arbitration-agreement',
}
