import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISuccessResponse } from 'interfaces/Common/ICommonContract';
import { ResetPasswordState } from './authenticationState';

const initialState: ResetPasswordState = {
  emailAddress: '',
  code: '',
  isCodeVerified: false,
  isPasswordReset: false,
  isPasswordResetCodeSent: false,
  userExists: undefined,
  isPasswordUpdated: false,
  isLoading: false,
};

const resetPasswordSlice = createSlice({
  name: 'resetPasswordSlice',
  initialState,
  reducers: {
    checkForValidUser(state, action) {
      return { ...state, emailAddress: action.payload };
    },
    setUserStatus(state, action) {
      const { exists } = action.payload;
      state.userExists = exists;
    },
    sendPasswordResetCode(state, action) {},
    setIsPasswordResetCodeSent(state, action: PayloadAction<ISuccessResponse>) {
      return { ...state, isPasswordResetCodeSent: action.payload.success };
    },
    verifyPasswordResetCode(state, action) {
      return { ...state, code: action.payload.code };
    },
    setCodeVerify(state, action: PayloadAction<ISuccessResponse>) {
      return { ...state, isCodeVerified: action.payload.success };
    },
    verifyPasswordReset(state, action) {},
    setPasswordReset(state, action: PayloadAction<ISuccessResponse>) {
      return { ...state, isPasswordReset: action.payload.success };
    },
    verifyPasswordUpdate(state, action) {},
    setPasswordUpdate(state, action: PayloadAction<ISuccessResponse>) {
      return { ...state, isPasswordUpdated: action.payload.success };
    },
    resetPasswordUpdate(state, action) {
      return { ...state, isPasswordUpdated: action.payload };
    },
    verifyAndResetPassword(state, action) {
      return { ...state, emailAddress: action.payload };
    },
    setLoadingAction(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  checkForValidUser,
  setUserStatus,
  sendPasswordResetCode,
  setIsPasswordResetCodeSent,
  verifyPasswordResetCode,
  setCodeVerify,
  verifyPasswordReset,
  verifyPasswordUpdate,
  setPasswordReset,
  setPasswordUpdate,
  resetPasswordUpdate,
  verifyAndResetPassword,
  setLoadingAction,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
