import React from 'react';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactComponent as NoJobDetails } from 'assets/images/Jobs/jobDetailsNotFound.svg';
import { AppLink } from 'components/common/Link/Link';
import { useHistory } from 'react-router-dom';
import { theme } from 'styles/theme';

export const NoJobDetailsSplash = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/jobs');
  };

  return (
    <Card
      sx={{
        padding: { sm: '24px', md: '48px' },
        height: { xs: '500px', md: '480px' },
        display: 'flex',
        width: { sm: '100%', md: '555px' },
        borderRadius: '10px',
        boxShadow: {
          sm: `0px 1px 15px ${theme.palette.system.boxShadowGray}`,
        },
      }}
    >
      <Grid
        container
        justifyContent={{ xs: 'space-between', md: 'center' }}
        alignContent="center"
        flexWrap="nowrap"
        flexDirection={{ xs: 'column', md: 'row' }}
        sx={{ padding: { xs: '24px 60px', md: '0px' } }}
      >
        <Grid container item>
          <Grid item md={10.5} mt="auto" mb="auto" justifyContent="center">
            <Typography
              variant="h3"
              color="system.midnightBlue"
              lineHeight="24px"
            >
              <i>Uh oh!</i>
              <br />
              <i>Job Not Found.</i>
            </Typography>
            <Divider
              sx={{
                backgroundColor: theme.palette.system.skyBlue,
                borderWidth: '2px',
                margin: '12px 0 24px 0',
                width: '100px',
              }}
            />
            <Typography
              variant="subtitle1"
              color="system.grayText"
              lineHeight="24px"
            >
              The job may not be available anymore or the link is wrong. No
              worries, we have a ton of great jobs for you in{' '}
              <AppLink
                onClick={handleClick}
                component={'span'}
                linkStyles={{
                  cursor: 'pointer',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                Job Search
              </AppLink>
              !
            </Typography>
          </Grid>
        </Grid>
        <Grid item display="flex" justifyContent="flex-end" alignItems="center">
          <NoJobDetails />
        </Grid>
      </Grid>
    </Card>
  );
};
