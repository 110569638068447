import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as service from 'services/lookup/lookupService';
import { setError } from 'store/slices/error/errorSlice';
import {
  createUserCode,
  setLoadingAction,
  setVerification,
} from '../slices/verificationCode/verificationCodeSlice';

function* sendVerificationCode(action) {
  yield put(setLoadingAction(true));
  try {
    const response = yield call(() => service.createUserCode(action.payload));
    const { data } = response;
    yield put(setVerification(data));
    yield put(setLoadingAction(false));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoadingAction(false));
  }
}

function* watchVerificationCode() {
  yield takeEvery(createUserCode.type, sendVerificationCode);
}

export function* verificationCodeSaga() {
  yield all([watchVerificationCode()]);
}
