import { createTheme } from '@mui/material';
import { theme } from 'styles/theme';

const StylesListItem = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: '47px',
  height: '32px',
  padding: '0px',
  paddingLeft: '25px',
  marginTop: '7px',
  marginBottom: '7px',
  minWidth: 'initial',
};

const commonFontProperties = {
  fontSize: 12,
  fontFamily: 'Open Sans, sans-serif',
};

let menuTheme = createTheme({
  typography: {
    body1: {
      ...commonFontProperties,
      fontSize: 14,
      fontWeight: 500,
      color: theme?.palette?.system?.midnightBlue,
    },
    body2: {
      ...commonFontProperties,
      color: theme?.palette?.system?.coolGray,
      lineHeight: '17px',
    },
    caption: {
      ...commonFontProperties,
      color: theme?.palette?.system?.grayText,
      fontWeight: 600,
      lineHeight: '17px',
    },
    h4: {
      ...commonFontProperties,
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
      color: theme?.palette?.system?.midnightBlue,
    },
  },
});

const StylesProfileMenu = {
  zIndex: '8000',
  padding: '0px',
};

const StylesProfileMenuMobile = {
  zIndex: '8000',
  padding: '0px',
  top: '0px',
};

const StylesMenuPaperProps = {
  style: {
    position: 'absolute' as 'absolute',
    transform: 'translateX(-6px)',
    boxShadow: `
    0px 2px 13px rgb(0 0 0 / 20%)
    `,
  },
};

const StylesMobileMenuPaperProps = {
  style: {
    position: 'absolute' as 'absolute',
    maxWidth: '104vw',
    width: '104vw',
    transform: 'translateX(22px)',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%)',
  },
};

const StylesProfileMenuInner = {
  paddingTop: '0px',
  paddingBottom: '0px',
};

const StylesProfileMenuInnerMobile = {
  paddingTop: '0px',
  paddingBottom: '0px',
  width: '102vw',
};

const StylesProfileRow = {
  padding: '0px',
  width: '288px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '&: focus-visible': {
    outline: `2px solid ${theme?.palette?.system?.white}` as string,
    backgroundColor: theme?.palette?.system?.backgroundGray as string,
    borderBottom: `1px solid ${theme?.palette?.system?.navBorder}` as string,
  },
  borderBottom: '1px solid',
  borderColor: theme?.palette?.system?.navBorder as string,
};

const StylesProfileRowMobile = {
  ...StylesProfileRow,
  width: '100%',
  paddingLeft: '24px',
};

const StylesProfileRowImageWrapper = {
  position: 'relative',
  minHeight: '80px',
  minWidth: '80px',
  width: '80px',
  paddingLeft: '24px',
  paddingTop: '24px',
  paddingBottom: '24px',
  '&: focus-visible': {
    backgroundColor: theme?.palette?.system?.backgroundGray,
  },
};

const StylesProfileRowImageWrapperInner = {
  borderRadius: '50%',
  width: 'inherit',
  height: '80px',
};

const StylesEditIconWrapper = {
  position: 'absolute',
  cursor: 'pointer',
  left: '54px',
  top: '54px',
  backgroundColor: theme?.palette?.system?.white,
  border: '4px solid',
  padding: '12px',
  borderColor: theme?.palette?.system?.skyBlue,
  borderRadius: '50%',
  transform: 'scale(0.46)',
};

const StylesUserInfoContainer = {
  marginLeft: '40px',
  display: 'flex',
  flexDirection: 'column',
  '&: focus-visible': {
    backgroundColor: theme?.palette?.system?.backgroundGray,
  },
};

const StylesTravelerIdWrapper = {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '6px',
  '&: focus-visible': {
    backgroundColor: theme?.palette?.system?.backgroundGray,
  },
};

const StylesMenuOption = {
  padding: '0px',
  paddingTop: '1px',
  paddingBottom: '1px',
  width: '288px',
  '&: last-child': {
    paddingBottom: '10px',
  },
};

const StylesMenuOptionMobile = {
  ...StylesMenuOption,
  width: '100%',
  paddingLeft: '24px',
};

const StyleMenuOptionText = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '12px',
};

export {
  StylesListItem,
  StylesProfileMenu,
  StylesProfileMenuMobile,
  StylesProfileMenuInner,
  StylesMenuPaperProps,
  StylesProfileMenuInnerMobile,
  StylesProfileRow,
  StylesProfileRowMobile,
  StylesProfileRowImageWrapper,
  StylesProfileRowImageWrapperInner,
  StylesEditIconWrapper,
  StylesUserInfoContainer,
  StylesTravelerIdWrapper,
  StylesMenuOption,
  StylesMenuOptionMobile,
  StyleMenuOptionText,
  StylesMobileMenuPaperProps,
  menuTheme as theme,
};
