import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import EditIcon from 'assets/icons/Edit/EditIcon';
import { AppButton } from 'components/common/Button/Button';
import { CustomerSupport } from 'components/common/CustomerSupport/CustomerSupport';
import AlertDialog from 'components/common/Dialog/ErrorDialog';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { AppLink } from 'components/common/Link/Link';
import { PRIVACY_POLICY_URL } from 'constants/passportUrls';
import { LoginWrapper } from 'containers/LoginContainer/LoginWrapper';
import {
  EmailEditContainer,
  LockoutMessageRow,
} from 'containers/LoginContainer/LoginWrapper.styles';
import { apiErrors } from 'enums/apiErrors';
import { openInNewTab } from 'helpers/openInNewTab';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { trackEvent } from 'services/logging/appInsights';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import {
  sendPasswordResetCode,
  verifyPasswordResetCode,
} from 'store/slices/authentication/resetPasswordSlice';
import { unSetError } from 'store/slices/error/errorSlice';
import {
  saveCreateUserAccountinStore,
  setLoadingAction,
  validateNewUserCode,
} from 'store/slices/user/userLogin/userLoginSlice';
import { createUserCode } from 'store/slices/verificationCode/verificationCodeSlice';
import { theme } from 'styles/theme';
import * as Yup from 'yup';
import logo from '../../../assets/images/Controls-Logo@2x.png';
import ResetCreateUserFlow from '../ResetUserFlow';

export const EmailVerification = () => {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState({ title: '', message: '' });
  const [invalidCode, setInvalidCodeError] = useState(false);

  const codeRef = useRef<string>('');

  const error: boolean = useAppSelector(state => state.error.error);
  const errorData: IErrorResponse = useAppSelector(
    state => state.error.errorResponse,
  );
  const userLoginState = useAppSelector(state => state.userExistStatus);
  const redirectRoute = useAppSelector(
    state => state.userExistStatus.redirectRoute,
  );
  const resetCodeVerified = useAppSelector(
    state => state.resetPassword.isCodeVerified,
  );

  const emailAddress = userLoginState.loginState.userEmail;
  const firstName = userLoginState.createAccountData.firstName;
  const lastName = userLoginState.createAccountData.lastName;
  const phoneNumber = userLoginState.createAccountData.phoneNumber;
  const isVerificationCodeSend = userLoginState.userVerificationStatus.success;

  const schema = Yup.object().shape({
    verificationCode: Yup.string().required('Verification code is required'),
  });

  const methods = useForm({
    defaultValues: {
      verificationCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, setValue, watch } = methods;

  const watchVerificationCode = watch('verificationCode');

  useEffect(() => {
    if (!emailAddress) {
      history.push(`/${AppRouteNames.EMAIL_VALIDATION}`);
      dispatch(setLoadingAction(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress]);

  useEffect(() => {
    if (isVerificationCodeSend) {
      dispatch(
        saveCreateUserAccountinStore({
          ...userLoginState.createAccountData,
          code: codeRef.current.toString(),
        }),
      );
      history.push(`/${AppRouteNames.CREATE_PASSWORD}`);
    }

    if (resetCodeVerified) {
      history.push(`/${AppRouteNames.CREATE_PASSWORD}`);
    }

    dispatch(setLoadingAction(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerificationCodeSend, resetCodeVerified]);

  useEffect(() => {
    if (error) {
      switch (errorData.errorCode) {
        case apiErrors.VerificationCodeNotFound:
        case apiErrors.VerificationCodeIsNotValid:
        case apiErrors.VerificationCodeFoundButIsInvalid:
          setErrorMessage({
            title: 'Code entered was invalid or expired',
            message:
              'If you continue to have issues, verify the code is correct, otherwise select “Re-Send Code”.',
          });
          setInvalidCodeError(true);
          break;
        case apiErrors.UserAccountSuspended:
          setErrorMessage({
            title: 'Your account is suspended due to too many failed attempts',
            message: 'To re-activate your account',
          });
          setInvalidCodeError(false);
          break;
        case apiErrors.PasswordResetMustGenerateNewCode:
          setErrorMessage({
            title: 'Too many validations have been attempted',
            message:
              'Please generate a new verification code and try again. If you continue to have issues',
          });
          setInvalidCodeError(false);
          break;
        default:
          setErrorMessage({
            title: 'Unable to process your request',
            message: 'If you continue to have issues',
          });
          setInvalidCodeError(false);
      }
    }
  }, [error, errorData.errorCode]);

  const handleClose = () => {
    dispatch(unSetError());
  };

  const resendVerificationCode = useCallback(() => {
    switch (redirectRoute) {
      case AppRouteNames.EMAIL_VERIFICATION:
        sendPasswordResetCode({
          emailAddress: emailAddress,
        });
        break;
      default:
        dispatch(
          createUserCode({
            emailAddress: emailAddress,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
          }),
        );
    }
  }, [dispatch, emailAddress, firstName, lastName, phoneNumber, redirectRoute]);

  const onSubmit = data => {
    codeRef.current = data.verificationCode;
    switch (redirectRoute) {
      case AppRouteNames.EMAIL_VERIFICATION:
        dispatch(
          verifyPasswordResetCode({
            emailAddress,
            code: data.verificationCode.toString(),
          }),
        );
        break;
      default:
        dispatch(
          validateNewUserCode({
            emailAddress: emailAddress,
            code: data.verificationCode.toString(),
          }),
        );
        trackEvent('acct_submit_verify_code');
    }
  };

  /**
   * Method to open link in new tab
   */
  const onClickUrl = (url: string) => () => openInNewTab(url);

  const checkWidth = () => {
    if (matches) {
      return '300px';
    } else {
      return '188px';
    }
  };

  const handleChange = e => {
    setValue('verificationCode', e.target.value, { shouldValidate: true });
  };

  const nextButtonDisabled = useMemo(() => {
    return watchVerificationCode.length !== 6;
  }, [watchVerificationCode]);

  return (
    <LoginWrapper>
      <AlertDialog
        open={error}
        handleClose={handleClose}
        title={errorMessage.title}
        dismissText="DISMISS"
        paperStyle={{ width: '280px' }}
      >
        <LockoutMessageRow>
          {invalidCode ? (
            <Grid
              container
              display="flex"
              justifyContent="left"
              sx={{
                color: 'system.coolGray',
                fontWeight: '400',
                lineHeight: '22px',
              }}
            >
              <Box>If you continue to have issues,</Box>
              <Box>verify the code is correct,</Box>
              <Box>otherwise select “Re-Send Code”.</Box>
            </Grid>
          ) : (
            <CustomerSupport text={errorMessage.message} displayType="popup" />
          )}
        </LockoutMessageRow>
      </AlertDialog>
      <Grid
        container
        item
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="300px"
        gap={2}
      >
        <Grid item mb={{ xs: 4, md: '125px' }}>
          <img src={logo} alt="amn-passport-logo" width={checkWidth()} />
        </Grid>
        <FormProvider {...methods}>
          <Grid item>
            <Typography
              variant="h2"
              color={theme.palette.system.midnightBlue}
              lineHeight="33px"
              textAlign="center"
              mb={3}
            >
              Enter the verification code we emailed you.
            </Typography>
            <EmailEditContainer
              style={{ display: 'flex', width: '300px', textAlign: 'center' }}
            >
              <Typography
                variant="subtitle1"
                fontWeight={700}
                sx={{ paddingRight: '6px' }}
              >
                {emailAddress && emailAddress?.length < 28
                  ? emailAddress
                  : emailAddress?.substring(0, 30) + '...'}
              </Typography>
              <span
                onClick={() => {
                  history.push(`/${AppRouteNames.EMAIL_VALIDATION}`);
                  dispatch(setLoadingAction(false));
                }}
              >
                {EditIcon(`${theme.palette.system.skyBlue}`)}
              </span>
            </EmailEditContainer>
          </Grid>
          <Grid item mb={3} width="100%">
            <ReactHookFormTextField
              label="Verification Code"
              name="verificationCode"
              onChange={e => handleChange(e)}
              maxLength={6}
            />
          </Grid>
          <Grid item width="100%">
            <Button
              id="next-button"
              variant="primary"
              size="medium"
              onClick={handleSubmit(onSubmit)}
              disabled={nextButtonDisabled}
              sx={{ width: '100%' }}
            >
              Next
            </Button>
          </Grid>
          <Grid item mb={1} width="100%">
            <AppButton
              id="login-button"
              children="Cancel"
              type="reset"
              width="100%"
              onClick={() => ResetCreateUserFlow(history, dispatch)}
              variant="secondary"
            />
          </Grid>
        </FormProvider>
        <Grid
          container
          item
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item display="flex" justifyContent="flex-end" mr={1}>
            <AppLink component="button" onClick={resendVerificationCode}>
              Re-Send Code
            </AppLink>
          </Grid>
          <Grid item display="flex" justifyContent="flex-start" ml={1}>
            <AppLink
              component="a"
              onClick={onClickUrl(PRIVACY_POLICY_URL)}
              underline="none"
            >
              Privacy Policy
            </AppLink>
          </Grid>
        </Grid>
      </Grid>
    </LoginWrapper>
  );
};
