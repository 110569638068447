import { IAcceptTerms } from 'store/slices/termsAndConditions/termsState';
import { http } from '../BaseApi';

const fetchTerms = async (): Promise<string> => {
  return await http.get('/auth/terms');
};

const acceptTerms = async (userTermsRequest: IAcceptTerms): Promise<string> => {
  return await http.patch(
    `/userprofile/${userTermsRequest.userId}/termsAndConditions`,
    {
      hasAcceptedTermsAndConditions:
        userTermsRequest.hasAcceptedTermsAndConditions,
    },
  );
};

export { acceptTerms, fetchTerms };
