import { IActionPayloadType } from 'interfaces/Common/IActionPayloadType';
import { IErrorAuthResponse } from 'interfaces/Common/ICommonContract';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as service from 'services/authentication/authenticationService';
import { activateLead } from 'services/domain/domainService';
import { trackEvent } from 'services/logging/appInsights';
import { getUserDetail } from 'services/user/userService';
import { RootState, resetState } from 'store/configureStore';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { setError, unSetError } from 'store/slices/error/errorSlice';
import {
  getUserProfile,
  setFeatureFlag,
  setFeatureToggles,
  setPromotionNotifications,
  setToolTips,
} from 'store/slices/user/userProfile/userProfileSlice';
import { createFlagDictionary } from 'utils/helper';
import { decodeToken } from 'utils/tokenHelper';
import {
  checkForValidUser,
  sendPasswordResetCode,
  setCodeVerify,
  setIsPasswordResetCodeSent,
  setLoadingAction,
  setPasswordReset,
  setPasswordUpdate,
  setUserStatus,
  verifyAndResetPassword,
  verifyPasswordReset,
  verifyPasswordResetCode,
  verifyPasswordUpdate,
} from '../slices/authentication/resetPasswordSlice';
import { toolTipsTourActions } from '../slices/toolTipsTour/toolTipsTourSlice';
import CobrowseIO from 'cobrowse-sdk-js';

const refreshToken = (state: RootState) =>
  state.auth.loginResponse.refreshToken;

const correlationId = (state: RootState) =>
  state.auth.loginResponse.correlationId;

// Worker Sagas
function* login(action) {
  yield put(authenticationActions.setLoadingAction(true));
  const { response, error } = yield call(service.login, action.payload);
  if (response) {
    // Create SSO with OneLogin
    try {
      const tokenResponse = yield call(service.session, action.payload);
      const { sessionToken, targetUrl } = yield tokenResponse.data;
      response.data.sessionToken = sessionToken;
      response.data.targetUrl = targetUrl;
      yield call(service.oneLoginSession, {
        url: targetUrl,
        sessionToken,
      });
    } catch (error: any) {
      console.error({ error });
      yield put(authenticationActions.setLoadingAction(false));
    }
    yield put(authenticationActions.setAccessToken(response.data.accessToken));
    yield put(
      authenticationActions.setRefreshToken(response.data.refreshToken),
    );
    yield put(authenticationActions.setLoggedInUser(response.data));
    const flagDict = createFlagDictionary(response.data?.featureFlags);
    yield put(setFeatureFlag(flagDict));
    yield put(setFeatureToggles(response.data?.featureToggles));
    yield put(
      setPromotionNotifications(response?.data?.promotionNotifications),
    );
    yield put(setToolTips(response?.data?.toolTips));
    yield put(toolTipsTourActions.restoreStateFromCookie());
    yield put(unSetError());
    trackEvent('Login_Successful');
  } else {
    yield put(setError(error.data));
    yield put(authenticationActions.setLoadingAction(false));
    trackEvent('Login_Failed');
  }
}

function* logout() {
  const token = yield select(refreshToken);
  try {
    resetState();
    yield put(authenticationActions.setLoggedOut());
    yield put(dataLayerActions.resetDataLayerAction());
    yield call(service.logout, token);
    CobrowseIO.stop();
  } catch (e) {
    console.error(e);
  } finally {
    trackEvent('Logout');
  }
}

function* getVersionCheckSaga() {
  try {
    const response = yield call(service.versionCheck, { version: '0.1.0' });
    if (response?.data) {
      yield put(authenticationActions.setVersionCheck(response.data));
    }
  } catch (error: any) {
    const err = error.data as IErrorAuthResponse;
    yield put(setError(err));
  }
}

function* sendResetCode(action) {
  try {
    const response = yield call(service.sendPasswordResetCode, action.payload);
    yield put(setIsPasswordResetCodeSent(response.data));
  } catch (error: any) {
    const err = error.data as IErrorAuthResponse;
    yield put(setError(err));
  }
}

function* passwordReset(action) {
  yield put(setLoadingAction(true));
  try {
    const response = yield call(service.passwordReset, action.payload);
    yield put(setPasswordReset(response.data));
    yield put(setLoadingAction(false));
  } catch (error: any) {
    const err = error.data as IErrorAuthResponse;
    yield put(setError(err));
    yield put(setLoadingAction(false));
  }
}

function* passwordUpdate(action) {
  try {
    const response = yield call(service.passwordUpdate, action.payload);
    yield put(setPasswordUpdate(response.data));
  } catch (error: any) {
    const err = error.data as IErrorAuthResponse;
    yield put(setError(err));
  }
}

function* verifyResetCode(action) {
  yield put(setLoadingAction(true));
  try {
    const response = yield call(service.validatePasswordReset, action.payload);
    yield put(setCodeVerify(response.data));
    yield put(setLoadingAction(false));
  } catch (error: any) {
    const err = error.data as IErrorAuthResponse;
    yield put(setError(err));
    yield put(setLoadingAction(false));
  }
}

function* getLoginUserState(action: IActionPayloadType) {
  try {
    const userName = action.payload as string;
    const userValidityResponse = yield call(() => getUserDetail(userName));
    yield put(setUserStatus(userValidityResponse.data));
  } catch (error: any) {
    const err = error.data as IErrorAuthResponse;
    yield put(setError(err));
  }
}

function* refreshAccessTokenSaga(action) {
  try {
    if (action.payload.refreshToken !== '') {
      const response = yield call(service.refreshAccessToken, action.payload);
      if (response) {
        yield put(
          authenticationActions.setAccessToken(response.data.accessToken),
        );
        yield put(
          authenticationActions.setRefreshToken(response.data.refreshToken),
        );
        const flagDict = createFlagDictionary(response.data?.featureFlags);
        yield put(setFeatureFlag(flagDict));
        yield put(setFeatureToggles(response.data?.featureToggles));
        yield put(
          setPromotionNotifications(response?.data?.promotionNotifications),
        );

        const decodedIdToken = decodeToken(response.data.idToken);

        if (
          decodedIdToken?.params?.PassportMobileAppUserStatusType === 'lead' &&
          decodedIdToken?.params?.PassportMobileAppUserHasUpgrades === 'true'
        ) {
          // if the user is a lead user and has upgrade, we want to activate the user
          yield put(
            authenticationActions.activateUserAction(
              decodedIdToken?.params?.PassportMobileAppUserId,
            ),
          );
        } else if (action.payload.updateLoggedInUser) {
          // update the logged in user and the user profile so that the recently upgraded lead user
          // can have full access as an active user
          yield put(authenticationActions.setLoggedInUser(response.data));
          yield put(
            getUserProfile(decodedIdToken?.params?.PassportMobileAppUserId),
          );
        }
      }
    }
  } catch (error: any) {
    console.error({ error });
  }
}

function* verifyResetPassword(action) {
  yield put(setLoadingAction(true));
  try {
    const userName = action.payload as string;
    const userValidityResponse = yield call(() => getUserDetail(userName));
    yield put(setUserStatus(userValidityResponse.data));
    if (userValidityResponse.data.exists) {
      try {
        const response = yield call(service.sendPasswordResetCode, {
          emailAddress: userName,
        });
        yield put(setIsPasswordResetCodeSent(response.data));
        yield put(setLoadingAction(false));
      } catch (error: any) {
        const err = error.data as IErrorAuthResponse;
        yield put(setError(err));
        yield put(setLoadingAction(false));
      }
    } else yield put(setLoadingAction(false));
  } catch (error: any) {
    yield put(setError(error));
    yield put(setLoadingAction(false));
  }
}

function* activateUser(action) {
  try {
    const isCreated = yield call(activateLead, action.payload);

    if (isCreated) {
      // once the user has been activated, refresh the access token
      // we pass an extra parameter updateLoggedInUser so that we update the user profile
      // and the logged in user
      const token = yield select(refreshToken);
      const correlationIdVal = yield select(correlationId);
      yield put(
        authenticationActions.refreshAccessTokenAction({
          refreshToken: token,
          correlationId: correlationIdVal,
          updateLoggedInUser: true,
        }),
      );
    }
  } catch (error: any) {
    yield put(setError(error));
  }
}

// Watcher Sagas
function* watchLogin() {
  yield takeEvery(authenticationActions.loginUserAction.type, login);
  yield takeEvery(
    authenticationActions.getVersionCheckAction.type,
    getVersionCheckSaga,
  );
}

function* watchLogout() {
  yield takeEvery(authenticationActions.logoutUserAction.type, logout);
}

function* watchRefreshAccessToken() {
  yield takeLatest(
    authenticationActions.refreshAccessTokenAction.type,
    refreshAccessTokenSaga,
  );
}

function* watchResetCode() {
  yield takeEvery(sendPasswordResetCode.type, sendResetCode);
}

function* watchPasswordReset() {
  yield takeEvery(verifyPasswordReset.type, passwordReset);
}

function* watchPasswordUpdate() {
  yield takeEvery(verifyPasswordUpdate.type, passwordUpdate);
}

function* watchVerifyPasswordResetCode() {
  yield takeEvery(verifyPasswordResetCode.type, verifyResetCode);
}

function* watchUserExists() {
  yield takeEvery(checkForValidUser.type, getLoginUserState);
}

function* watchResetAndVerifyPasswordReset() {
  yield takeLatest(verifyAndResetPassword.type, verifyResetPassword);
}

function* watchActivateUser() {
  yield takeLatest(authenticationActions.activateUserAction.type, activateUser);
}

export function* authSaga() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchRefreshAccessToken(),
    watchResetCode(),
    watchPasswordReset(),
    watchVerifyPasswordResetCode(),
    watchUserExists(),
    watchPasswordUpdate(),
    watchResetAndVerifyPasswordReset(),
    watchActivateUser(),
  ]);
}
