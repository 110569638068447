import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CheckIconFill } from 'assets/icons/Check/CheckIcon-fill';
import { HelpIcon } from 'assets/icons/HelpIcon';
import { ExternalLinkDrawer } from 'components/ActionItem/Drawer/ExternalLinkDrawer';
import { LeadDialogWrapper } from 'components/Lead/LeadDialogWrapper';
import { buttonSizes } from 'components/common/Button/Button.styles';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { TippyToolTip } from 'components/common/ToolTip/TippyToolTip';
import { ToolTipsTour } from 'components/common/ToolTipsTour/ToolTipsTour';
import Upslider from 'components/common/Upslider/Upslider';
import {
  useMobileMediaQuery,
  useSmMediaQuery,
} from 'containers/MobileDesktopContainer/useMediaQuery';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import { DrawerButtonTypes } from 'enums/buttons';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { DrawerHeaderType } from 'enums/headerTypes';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { getFormattedDateOnly, getMMMddyyyy } from 'helpers/dateFormatter';
import { isSafariOrFirefox, openInCurrentTab } from 'helpers/openInNewTab';
import { IApplyJobCardProps } from 'interfaces/Props/IApplyJobCardProps';
import { IContact } from 'interfaces/User/Contact/IContact';
import { useHistory } from 'react-router-dom';
import { getSecureContent } from 'services/domain/domainService';
import { trackEvent } from 'services/logging/appInsights';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { setApplyJobExternalLinkDrawerClose } from 'store/slices/jobs/ApplyJobsSlice';
import { toolTipsTourActions } from 'store/slices/toolTipsTour/toolTipsTourSlice';
import { theme } from 'styles/theme';
import useQueryParams from 'utils/hooks/useQueryParams';
import QuestionsDrawerTemplate from '../Questions/QuestionsDrawerTemplate';
import { ApplyJobToMultipleRecruiterDialog } from './ApplyJobToMultipleRecruiterDialog';
import { ApplyJobStatusMessageMobileDialog } from './Dialog/ApplyJobStatusMessageMobileDialog';
import { ApplyJobStatusMessageCard } from './Message/ApplyJobStatusMessageCard';
import { ApplyJobStatusMessageContentMobile } from './Message/ApplyJobStatusMessageContentMobile';
import { PreferredRecruiterDialog } from './PreferredRecruiterDialog';

export const ApplyJobCard = (props: IApplyJobCardProps) => {
  const {
    id,
    setSelectedRecruiters,
    handleApplyJob,
    isMFS,
    appliedDate,
    jobId,
    isExpanded = true,
    isStrike = false,
    secondaryAction,
    similarJobs,
    onScrollIntoView,
  } = props;

  const dispatch = useAppDispatch();
  const query = useQueryParams();
  const history = useHistory();
  const isMobileDevice = useMobileMediaQuery();
  const isTabletDevice = useSmMediaQuery();

  const disclaimerRef = useRef<HTMLDivElement>(null);

  const userId = useAppSelector(state => state.auth.userId);
  const applicationStatus = useAppSelector(state => state.applyJob);
  const signUpUrl = useAppSelector(state => {
    const versionCheck = state.auth?.versionCheck;

    if (!isStrike) {
      return versionCheck
        ? versionCheck['formstackStrikeSignUpFormUrl']
            .replace('{userId}', `${userId}`)
            .replace('{jobId}', `${jobId}`)
        : '';
    }

    return applicationStatus?.strikeJobSignUpUrl || '';
  });
  const userStatusTypeId = useAppSelector(
    state => state.userProfile?.userStatusTypeId,
  );
  const preferredRecruiterId = useAppSelector(
    state => state.userPreference.userPreference?.preferredRecruiter,
  ) as number | null;
  const userContacts = useAppSelector(state =>
    !isStrike
      ? state.userContact.userContacts.userContacts
      : [state.userContact.strikeContactState],
  ) as IContact[];

  const recruiters = userContacts?.filter(
    g => g.employeeRoleId === AssignmentRoleId.RECRUITER,
  );

  const toolTipsTourData = useAppSelector(
    state => state?.toolTipsTour?.toolTipsTourDetail,
  );

  const isNotStrikeFilter = query.get('filter') !== 'strike';

  const [applyJobDialogOpen, setApplyJobDialogOpen] = useState(false);
  const [messageCardOpen, setMessageCardOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [showQuestionsDrawer, setShowQuestionsDrawer] = useState(false);
  const [showPreferredRecruiter, setPreferredRecruiter] = useState(false);
  const [authUrl, setAuthUrl] = useState('');
  const [navigation, setNavigation] = useState(false);

  const onApplyNowClick = () => {
    dispatch(dataLayerActions.dataLayerAddJobAction());
    if (!isStrike) {
      if (preferredRecruiter?.employeeId) {
        handleApplyJob([preferredRecruiter?.employeeId]);
      } else if (
        !recruiters ||
        recruiters?.length === 0 ||
        (recruiters.length === 1 && !recruiters[0]?.employeeId)
      ) {
        handleApplyJob([]);
      } else if (recruiters.length === 1 && !!recruiters[0]?.employeeId) {
        handleApplyJob([recruiters[0]?.employeeId]);
      } else {
        setApplyJobDialogOpen(true);
      }
      isMobileDevice && setMessageDialogOpen(true);
    } else {
      handleApplyJob([]);
    }
    trackEvent('Interested_In_Job', {
      userId: `${userId}`,
      jobId: `${jobId}`,
      eventDate: new Date().toString(),
      isToolTipActive: toolTipsTourData.showToolTip,
    });
  };

  const getDetailsByUrl = async contentUrl => {
    await getSecureContent(
      contentUrl +
        `&redirectUrl=${window.location.origin}/jobs?` +
        encodeURIComponent(`jobId=${jobId}`),
    ).then(response => {
      setAuthUrl(response.url);
    });
  };

  const toggleQuestionsDrawer = (
    event: React.KeyboardEvent | React.MouseEvent,
    open: boolean,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    event && event.stopPropagation();
    setShowQuestionsDrawer(open);
  };

  const preferredRecruiter = userContacts?.find(g =>
    !isStrike
      ? g.employeeRoleId === AssignmentRoleId.RECRUITER &&
        g.employeeId === preferredRecruiterId
      : g.employeeRoleId === AssignmentRoleId.RECRUITER,
  );

  const handleCancelClick = () => {
    setApplyJobDialogOpen(false);
  };

  const handleSimilarJobsLinkClick = useCallback(() => {
    onScrollIntoView?.();
  }, [onScrollIntoView]);

  const handleMobileQuestionClick = () => {
    setShowQuestionsDrawer(true);
  };

  const handleMobileJobStatusMessageCancelClick = () => {
    setMessageDialogOpen(prevOpen => !prevOpen);
  };

  const handleCancelPreferredRecrutier = () => {
    setPreferredRecruiter(false);
  };

  const handleSendClick = () => {
    setApplyJobDialogOpen(false);
    setMessageDialogOpen(true);
    showPreferredRecuriter();
    dispatch(dataLayerActions.dataLayerApplyJobAction());
    trackEvent('Interested_In_Job', {
      userId: `${userId}`,
      jobId: `${jobId}`,
      eventDate: new Date().toString(),
      isToolTipActive: toolTipsTourData.showToolTip,
    });
    dispatch(toolTipsTourActions.hideToolTip());
  };

  const showPreferredRecuriter = () => {
    if (
      !preferredRecruiterId &&
      userContacts.filter(g => g.employeeRoleId === AssignmentRoleId.RECRUITER)
        ?.length > 1
    ) {
      //Show Preferred Recruiter when no preferredRecruiter and more than 1 recruiter available
      setPreferredRecruiter(true);
    }
  };

  const createDescription = useCallback(
    (date: string) => {
      const appliedOn = date !== '' ? date : new Date().toString();
      return isStrike && isExpanded
        ? `Signed Up on ${getMMMddyyyy(appliedOn)}`
        : '';
    },
    [isExpanded, isStrike],
  );

  const createShortDescription = useCallback(
    (date: string) => {
      const appliedOn = date !== '' ? date : new Date().toString();
      return !isStrike
        ? `Sent ${getFormattedDateOnly(appliedOn, 'MMM DD, YYYY')}`
        : `Signed Up on ${getFormattedDateOnly(appliedOn, 'MMM DD')}`;
    },
    [isStrike],
  );

  useEffect(() => {
    if (navigation && isStrike) getDetailsByUrl(signUpUrl);
  }, [isSafariOrFirefox, navigation, isStrike]);

  useEffect(() => {
    if (!!authUrl) openInCurrentTab(authUrl);
  }, [authUrl]);

  useEffect(() => {
    if (
      (applicationStatus.applyJob.jobId === jobId &&
        applicationStatus.hasApplied) ||
      appliedDate
    ) {
      setApplyJobDialogOpen(false);
      setMessageCardOpen(true);
    } else {
      setMessageCardOpen(false);
    }
  }, [
    jobId,
    applicationStatus.applyJob.jobId,
    applicationStatus.hasApplied,
    userContacts,
    isStrike,
    appliedDate,
    preferredRecruiterId,
    preferredRecruiter,
  ]);

  useEffect(() => {
    //This is formstack message event handler
    const formStackMessegeHandler = event => {
      if (event?.data === 'fs-form-submit') {
        setTimeout(() => {
          dispatch(setApplyJobExternalLinkDrawerClose());
          secondaryAction && secondaryAction();
        }, 3000);
      }
    };
    window.addEventListener('message', formStackMessegeHandler);
    return () => {
      window.removeEventListener('message', formStackMessegeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Strike formStack for firefox/safari
  useEffect(() => {
    const strikeFormStackJobId = query.get('jobId');
    if (isSafariOrFirefox && !!strikeFormStackJobId) {
      setNavigation(false);
      secondaryAction && secondaryAction();
      setTimeout(() => {
        // Delete from query params since they are no longer needed
        history.replace('/jobs');
      }, 1);
    }
  }, [isSafariOrFirefox]);

  useEffect(() => {
    if ((appliedDate ?? '') !== '') {
      setMessageCardOpen(true);
    } else {
      setMessageCardOpen(false);
    }
  }, [appliedDate]);

  const mfsDisclaimer = useMemo(() => {
    return isMFS ? (
      <Box
        sx={{
          maxWidth: { md: '148px', lg: '177px' },
          marginBottom: isMobileDevice ? '0px' : '12px',
        }}
        id="mfs-disclaimer"
        ref={disclaimerRef}
      >
        {!isMobileDevice && (
          <Divider
            sx={{
              marginBottom: '12px',
            }}
          />
        )}
        <Typography
          variant="body1"
          color={theme.palette.system.coolGray}
          lineHeight="19px"
        >
          This is a popular job and isn't accepting new applications at this
          time.&nbsp;
          {similarJobs ? (
            <>
              View
              <span
                onClick={handleSimilarJobsLinkClick}
                style={{
                  cursor: 'pointer',
                  color: theme.palette.system.skyBlue,
                }}
              >
                &nbsp;similar jobs&nbsp;
              </span>
              or share job interest with your recruiter?
            </>
          ) : (
            <span>
              Share your interest in jobs like this with your recruiter?
            </span>
          )}
        </Typography>
        {isMobileDevice && (
          <Divider
            sx={{
              margin: '12px 0',
            }}
          />
        )}
      </Box>
    ) : null;
  }, [isMFS, similarJobs, handleSimilarJobsLinkClick, isMobileDevice]);

  useEffect(() => {
    if (isMFS && disclaimerRef.current) {
      let mb = disclaimerRef.current.offsetHeight + 14;
      const siteFooter = document.getElementById('site-footer');
      if (siteFooter) {
        siteFooter.style.marginBottom = `${mb}px`;
      }
    }
  }, [disclaimerRef, isMFS]);

  const upslider = React.useMemo(
    () => (
      <Upslider
        open={
          !isStrike && messageDialogOpen && (isMobileDevice || isTabletDevice)
        }
        toggleDrawer={handleMobileJobStatusMessageCancelClick}
        render={
          <ApplyJobStatusMessageContentMobile
            id="apply-job-message"
            message={createShortDescription(appliedDate ?? '')}
            handleMobileQuestionClick={handleMobileQuestionClick}
            iconSIze={'22px'}
          />
        }
      />
    ),
    [
      appliedDate,
      createShortDescription,
      isMobileDevice,
      isStrike,
      isTabletDevice,
      messageDialogOpen,
    ],
  );

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            position: 'fixed',
            bottom: '0px',
            left: '0',
            padding: '22px 24px 21px 24px',
            backgroundColor: theme.palette.system.white,
            boxShadow: '0px -1px 15px #75787B40',
            zIndex: 1,
          },
        }}
        onClick={e => {
          // Prevents parent job details from expanding
          e?.stopPropagation?.();
        }}
      >
        <Box
          id={id}
          sx={{
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              display: !messageCardOpen ? 'flex' : 'none',
            },
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
            },
          }}
        >
          {mfsDisclaimer}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {userStatusTypeId === UserStatusTypeId.ACTIVE && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                onClick={handleMobileQuestionClick}
              >
                <Box
                  id="question-icon"
                  sx={{
                    [theme.breakpoints.up('sm')]: {
                      display: 'none',
                    },
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <HelpIcon width="17px" height="17px" />
                </Box>
                <Typography
                  sx={{
                    [theme.breakpoints.up('sm')]: {
                      display: 'none',
                    },
                    [theme.breakpoints.down('sm')]: {
                      display: 'flex',
                    },
                    textAlign: 'left',
                    pl: '6px',
                    color: {
                      xs: 'system.skyBlue',
                      sm: 'system.grayText',
                    },
                    font: 'normal normal normal 16px/22px Open Sans',
                  }}
                >
                  Questions?
                </Typography>
              </Box>
            )}
            <LeadDialogWrapper>
              <Button
                id={`Btn-ApplyJob-${id}`}
                variant="contained"
                sx={{
                  display:
                    (applicationStatus.applyJob.jobId === jobId &&
                      applicationStatus.hasApplied) ||
                    (appliedDate ?? '') !== ''
                      ? 'none'
                      : 'flex',
                  width:
                    (applicationStatus.applyJob.jobId === jobId &&
                      applicationStatus.hasApplied) ||
                    (appliedDate ?? '') !== ''
                      ? '0px'
                      : `${buttonSizes.md}`,

                  maxWidth: { md: '148px', lg: '177px' },
                  height: '42px',
                  marginLeft: {
                    xs: '12px !important',
                    sm: '0px !important',
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: `${
                      userStatusTypeId === UserStatusTypeId.LEAD
                        ? '100%'
                        : '166px'
                    }`,
                    marginLeft: `${
                      userStatusTypeId === UserStatusTypeId.LEAD
                        ? '0px !important'
                        : '12px !important'
                    }`,
                  },
                  backgroundColor: theme.palette.system.midnightBlue,
                  textTransform: 'none',
                  padding: '12px',
                  '&:hover': {
                    backgroundColor: '#0b5792',
                  },
                  fontSize: '16px',
                  fontWeight: '400',
                }}
                onClick={() => {
                  if (isSafariOrFirefox) setNavigation(true);
                  onApplyNowClick();
                  dispatch(toolTipsTourActions.setIndex(3));
                }}
                data-testid="apply-job"
                disabled={applicationStatus?.isLoading}
              >
                {isMFS ? 'Share Interest' : !isStrike ? 'Apply Now' : 'Sign Up'}
              </Button>
              <Button
                id="Btn-ApplyDialogOpen"
                sx={{
                  display:
                    (applicationStatus.applyJob.jobId === jobId &&
                      applicationStatus.hasApplied) ||
                    (appliedDate ?? '') !== ''
                      ? { xs: 'flex', md: 'none' }
                      : 'none',
                  height: '42px',
                  backgroundColor: 'system.inputBackground',
                  textTransform: 'none',
                  fontSize: '14px',
                  lineHeight: '18px',
                  width: 'auto',
                  fontWeight: '600',
                  color: 'system.midnightBlue',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#e4e3e3',
                    boxShadow: 'none',
                  },
                  whiteSpace: 'nowrap',
                  padding: '12px 24px',
                }}
                startIcon={
                  <CheckIconFill
                    sx={{
                      color: theme.palette.system.skyBlue,
                      width: 24,
                      height: 24,
                    }}
                  />
                }
                onClick={() => setMessageDialogOpen(true)}
                data-testid="apply-dialog-open"
              >
                {appliedDate !== ''
                  ? createShortDescription(appliedDate ?? '')
                  : ''}
              </Button>
            </LeadDialogWrapper>
          </Box>
        </Box>
      </Box>

      <ApplyJobToMultipleRecruiterDialog
        id="apply-job-confirm"
        setSelectedRecruiters={setSelectedRecruiters}
        open={applyJobDialogOpen}
        handleCancelClick={handleCancelClick}
        handleSendClick={handleSendClick}
        handleApplyJob={handleApplyJob}
      />
      <PreferredRecruiterDialog
        open={showPreferredRecruiter}
        handleCancelClick={handleCancelPreferredRecrutier}
      />
      <ApplyJobStatusMessageCard
        id="apply-job-message"
        open={messageCardOpen}
        isStrike={isStrike}
        isExpanded={isExpanded}
        message={
          !isStrike ? (
            'This job has been sent to your recruiter.'
          ) : isExpanded ? (
            <>
              <Typography>Thank you!</Typography>
              <Typography sx={{ padding: '0 14px' }}>
                We have captured your interest in this event.
              </Typography>
            </>
          ) : (
            'Signed Up'
          )
        }
        description={
          isStrike
            ? createDescription(appliedDate ?? '')
            : createShortDescription(appliedDate ?? '')
        }
      />
      <ApplyJobStatusMessageMobileDialog
        id="apply-job-mobile-message"
        open={isStrike && messageDialogOpen}
        message={
          !isStrike
            ? 'This job has been sent to your recruiter.'
            : 'Thank you! We have captured your interest in this event.'
        }
        description={createDescription(appliedDate ?? '')}
        handleCancelClick={handleMobileJobStatusMessageCancelClick}
        sx={{
          [theme.breakpoints.up('sm')]: {
            display: 'none',
          },
        }}
        isStrike={isStrike}
      />
      {/* start: web-responsive - render upslider in apply/sent button click */}
      {!isStrike && messageDialogOpen && (
        <Grid
          sx={{
            [theme.breakpoints.up('sm')]: {
              display: 'none',
            },
          }}
        >
          {upslider}
        </Grid>
      )}
      {/* end: web-responsive - render upslider in apply/sent button click */}
      <DrawerPanel
        showFooter={false}
        open={showQuestionsDrawer}
        toggleDrawer={toggleQuestionsDrawer}
        actionButtonText={''}
        cancelButtonText={''}
        secondaryButtonType={DrawerButtonTypes.CANCEL}
        previousDrawer={DrawerContentNames.CLOSED_DRAWER}
        contentName={'Questions?'}
        contentStyles={{ height: '100%' }}
        headerType={DrawerHeaderType.Close}
        goBackNotClose={false}
      >
        <QuestionsDrawerTemplate isStrike={isStrike}></QuestionsDrawerTemplate>
      </DrawerPanel>
      {applicationStatus?.externalLinkDrawerForStrikeJobApply && (
        <ExternalLinkDrawer
          showFooter={false}
          width="100%"
          title={'Signup'}
          url={signUpUrl || ''}
          onClose={() => {
            dispatch(setApplyJobExternalLinkDrawerClose());
          }}
        />
      )}
      {isNotStrikeFilter && (
        <ToolTipsTour id="100" index={1}>
          <TippyToolTip
            id="ApplyJob-1"
            selector="#amn-card-container"
            placement="right"
            message="Select to learn more!"
          />
        </ToolTipsTour>
      )}
      {appliedDate === null && isNotStrikeFilter && (
        <>
          <ToolTipsTour id="100" index={2}>
            <TippyToolTip
              id="ApplyJob-2"
              selector="#Btn-ApplyJob-apply-job"
              placement="top"
              message="Select here to apply!"
            />
          </ToolTipsTour>

          <ToolTipsTour id="100" index={2} isMobile>
            <TippyToolTip
              id="ApplyJob-2-mobile"
              selector="#Btn-ApplyJob-apply-job-mobile"
              placement="top"
              message="Select here to apply!"
            />
          </ToolTipsTour>
        </>
      )}
    </>
  );
};
