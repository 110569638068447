import {
  DEFAULT_FILTER_TYPES,
  NEWEST_SORT_FILTER,
  PAY_SORT_FILTER,
  START_DATE_SORT_FILTER,
} from 'constants/jobFilter';
import { JobFilterType } from 'enums/jobFilterType';
import { JobSortType } from 'enums/jobSortType';
import { jobSortTypeText } from 'enums/jobSortTypeText';
import { IACSFilter } from 'interfaces/Jobs/IACSFilter';
import { IACSFilterType } from 'interfaces/Jobs/IACSFilterType';

export const getModifiedFilterType = (
  filterItem?: IACSFilter,
  requestFilterTypes?: IACSFilter[],
  filterType?: JobFilterType,
  displayZeroCountItems?: boolean,
) => {
  let types: IACSFilterType[] = [];
  if (requestFilterTypes) {
    const profFilter = requestFilterTypes?.find(
      filter => filter.filterType === filterType,
    );

    if (profFilter?.values && profFilter?.values?.length > 0) {
      types =
        filterItem?.values
          ?.filter(
            f =>
              (f.count ?? 0) > 0 ||
              requestFilterTypes
                ?.map(x => x.values)
                ?.flat()
                ?.some(x => x.displayName === f.displayName) ||
              displayZeroCountItems,
          )
          ?.map(filter => ({
            type: `${filter.displayName} (${filter?.count ?? 0})`,
            isActive: profFilter?.values?.some(
              s => s.displayName === filter.displayName,
            ),
          })) ?? [];
    } else {
      types =
        filterItem?.values?.map(filter => ({
          type: `${filter.displayName} (${filter?.count ?? 0})`,
          isActive: false,
        })) ?? [];
    }
  } else {
    types =
      filterItem?.values?.map(filter => ({
        type: `${filter.displayName} (${filter?.count ?? 0})`,
        isActive: false,
      })) ?? [];
  }
  return types;
};

export const findSelectedItems = (
  filterType: IACSFilterType[],
  modifiedType: IACSFilterType[],
) => {
  return (
    filterType
      ?.filter(
        x =>
          x.isActive &&
          !modifiedType?.some(y =>
            y.type.includes(getTextFromFacetName(x.type) ?? ''),
          ),
      )
      .map(x => ({
        type: (getTextFromFacetName(x.type) ?? '') + ' (0)',
        isActive: x.isActive,
      })) ?? []
  );
};
export const getCityState = (
  geocodeResult,
): { city: string; state: string } => {
  const city = geocodeResult.address_components.find(a =>
    a.types.includes('locality'),
  )?.long_name;
  const state = geocodeResult.address_components.find(a =>
    a.types.includes('administrative_area_level_1'),
  )?.short_name;

  return { city, state };
};

export const generateRequest = (
  filterList,
  requestFilter,
  filterType: JobFilterType,
  reset?: boolean,
) => {
  const filter = requestFilter?.filterTypes?.find(
    f => f?.filterType === filterType,
  );
  let newFilter = requestFilter?.filterTypes;
  const otherFilter = requestFilter?.filterTypes?.filter(
    f => f?.filterType !== filterType,
  );
  if (filter && filterList?.length > 0) {
    newFilter = [
      ...(otherFilter ?? []),
      {
        filterType: filterType,
        values: filterList
          .filter(f => f.isActive)
          .map(f => ({
            displayName: getTextFromFacetName(f.type) ?? '',
            count: getCountFromFacetName(f.type) ?? 0,
          })),
      },
    ];
  }

  if (reset) {
    newFilter = [
      ...(otherFilter ?? []),
      { filterType: filterType, values: [] },
    ];
  }

  const newRequest = {
    ...requestFilter,
    pageNumber: '1',
    filterTypes: newFilter,
    currentFilterType: filterType,
  };

  return newRequest;
};

export const getCountFromFacetName = (input: string): string | null => {
  const match = input.match(/\(([^)]+)\)$/);
  return match ? match[1] : null;
};

export const getTextFromFacetName = (input: string): string | null => {
  const match = input.match(/^(.*)\s*\([^()]*\)$/);
  return match ? match[1].trim() : null;
};

export const getSortTypeName = (sortType: jobSortTypeText): string => {
  switch (sortType) {
    case jobSortTypeText.MAX_PAY_RATE:
      return PAY_SORT_FILTER;
    case jobSortTypeText.NEWEST:
      return NEWEST_SORT_FILTER;
    case jobSortTypeText.START_DATE:
      return START_DATE_SORT_FILTER;
  }
};

export const getSortTypeId = (sortType: jobSortTypeText): number => {
  switch (sortType) {
    case jobSortTypeText.MAX_PAY_RATE:
      return JobSortType.WeeklyPay;
    case jobSortTypeText.NEWEST:
      return JobSortType.MostRecentPost;
    case jobSortTypeText.START_DATE:
      return JobSortType.StartDate;
  }
};

export const modifyInitFilterWithDefaultFilter = defaultFilter => {
  let prefilter: any[] = DEFAULT_FILTER_TYPES;

  let exists = false;
  defaultFilter?.forEach(element => {
    prefilter = prefilter.map(x => {
      if (x.filterType === JobFilterType.COMMUNITIES) {
        exists = x.values.some(v => v.displayName === element?.community);
        return {
          ...x,
          values: exists
            ? [...x.values]
            : [...x.values, { displayName: element?.community }],
        };
      } else if (x.filterType === JobFilterType.DISCIPLINE) {
        exists = x.values.some(v => v.displayName === element?.disciplineName);
        return {
          ...x,
          values: exists
            ? [...x.values]
            : [...x.values, { displayName: element?.disciplineName }],
        };
      } else if (x.filterType === JobFilterType.SPECIALTY) {
        exists = x.values.some(v => v.displayName === element?.specialtyName);
        return {
          ...x,
          values: exists
            ? [...x.values]
            : [...x.values, { displayName: element?.specialtyName }],
        };
      } else {
        return x;
      }
    });
  });

  return prefilter;
};
