import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as service from 'services/termsAndConditions/termsService';
import {
  acceptTermsAndConditions,
  fetchTerms,
  setTerms,
  termsAccepted,
} from 'store/slices/termsAndConditions/termsSlice';
import { IAcceptTerms } from 'store/slices/termsAndConditions/termsState';
import { setTermsAndConditons } from 'store/slices/user/userProfile/userProfileSlice';

// Worker Sagas
export function* getTermsSaga(action) {
  try {
    const response = yield call(service.fetchTerms);
    const { data } = response;
    yield put(setTerms({ ...data }));
  } catch (error) {}
}

export function* acceptTermsAndConditionsSaga(action) {
  const termsAcceptedRequest = action.payload as IAcceptTerms;
  try {
    const response = yield call(service.acceptTerms, termsAcceptedRequest);
    const { isUpdated } = response.data;
    if (isUpdated) {
      yield put(termsAccepted());
      yield put(setTermsAndConditons(isUpdated));
    }
  } catch (error) {}
}

// Watcher Sagas
function* watchGetTerms() {
  yield takeLatest(fetchTerms.type, getTermsSaga);
}

function* watchAcceptTermsAndConditions() {
  yield takeLatest(acceptTermsAndConditions.type, acceptTermsAndConditionsSaga);
}

// Root Terms Saga
export function* termsSaga() {
  yield all([watchGetTerms(), watchAcceptTermsAndConditions()]);
}
