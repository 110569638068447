import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { ISsoTokenResponse } from 'types/SsoResponse';
import { SsoState } from './ssoState';

const initialState: SsoState = {
  tokenResponse: {
    targetUrl: '',
    samlToken: '',
  },
  error: false,
  ssoError: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
};

const getConduentToken = createAction<string>('GET_CONDUENT_TOKEN');
const getKaiserToken = createAction('GET_KAISER_TOKEN');

const ssoSlice = createSlice({
  name: 'ssoSlice',
  initialState: initialState,
  reducers: {
    setToken(state, action: PayloadAction<ISsoTokenResponse>) {
      state.tokenResponse.targetUrl = action.payload.targetUrl;
      state.tokenResponse.samlToken = action.payload.samlToken;
      if (state.error) {
        state.error = false;
        state.ssoError = {
          ...initialState.ssoError,
        };
      }
    },
    unSetToken() {
      return { ...initialState };
    },
    setSsoError(state, action: PayloadAction<IErrorResponse>) {
      state.tokenResponse = {
        ...initialState.tokenResponse,
      };
      state.error = true;
      state.ssoError.errorCode = action.payload.errorCode;
      state.ssoError.errorData = action.payload.errorData;
      state.ssoError.errorDetails = action.payload.errorDetails;
      state.ssoError.errorMessage = action.payload.errorMessage;
      state.ssoError.operationId = action.payload.operationId;
      state.ssoError.correlationId = action.payload.correlationId;
    },
    unSetSsoError() {
      return { ...initialState };
    },
  },
});

export const ssoActions = {
  ...ssoSlice.actions,
  getConduentToken,
  getKaiserToken,
};
export default ssoSlice.reducer;
