export enum ActiveProgressTrackerMessage {
  APPLIED_PENDING_QUALIFICATION_ITEMS = 'Due to limited positions, complete items quickly to be considered for this strike.',
  APPLIED_NO_QUALIFICATION_ITEM_PENDING = 'We are reviewing your qualification items and processing your application.',
  SUBMITTED_POST_NOTICE = 'Complete the Strike Confirmation Form to be considered for this event.',
  SUBMITTED_NONE_NO_REMAINING_CREDENTIALS = 'Availability confirmed. Hold tight while we prepare your travel details.',
  SUBMITTED_TRAVEL_BOOKED = 'Review your travel details to increase chance of being scheduled.',
  SUBMITTED = 'Await strike notice. Monitor action items and ensure your credentials are up to date.',
  CONFIRMED_TRAVEL_CONFIRMED = 'Await "Cleared to Travel" notice. Due to flexible assignment status, complete items quickly.',
  CONFIRMED_CLEARED_TO_TRAVEL_NOT_ACCEPTED = 'You are cleared to travel. Please acknowledge when you start to travel by tapping button below.',
  CONFIRMED_CLEARED_TO_TRAVEL_ACCEPTED = 'Safe Travels! Follow your emailed Travel & Check-In instructions and contact Strike Support for delays.',
  ARRIVED = 'Once checked into the event, contact the Clinicals Manger on-duty for questions related to your assignment.',
}

export enum ActiveAssignmentUpdate {
  APPLIED_PENDING_QUALIFICATION_ITEMS = 'Complete {Qualification Items}',
  APPLIED_NO_QUALIFICATION_ITEM_PENDING = 'Qualification Items Submitted',
  SUBMITTED_POST_NOTICE = 'Complete {Strike Confirmation Form}',
  SUBMITTED_NONE_NO_REMAINING_CREDENTIALS = 'Monitor Passport while travel details are prepared',
  SUBMITTED_TRAVEL_BOOKED = 'Confirm {travel details}',
  SUBMITTED_UPDATE_AWAIT_STRIKE_NOTICE = 'Await Strike Notice',
  SUBMITTED_UPDATE_MONITOR_PASSPORT_UPDATES = 'Monitor Passport for updates',
  CONFIRMED_TRAVEL_CONFIRMED_SIGN_PROFFESSIONAL_SERVICE_AGREEMENT = 'Sign Professional Service Agreement',
  CONFIRMED_TRAVEL_CONFIRMED_UPLOAD_PENDING_CREDENTIALS = 'Upload pending {Credentialing Items}',
  CONFIRMED_TRAVEL_CONFIRMED_AWAIT_CLEARED_TO_TRAVEL_NOTICE = 'Await "Cleared to Travel" notice',
  CONFIRMED_CLEARED_TO_TRAVEL_NOT_ACCEPTED_DEPART_FROM_ORIGINATING_CITY = 'Depart from originating city',
  CONFIRMED_CLEARED_TO_TRAVEL_NOT_ACCEPTED_CONFRIM_TRAVEL = 'Confirm travel started by selecting button on assignment details',
  CONFIRMED_CLEARED_TO_TRAVEL_ACCEPTED_NOTIFY_STRIKE_SUPPORT = 'Travel delays may impact strike assignment, notify {Strike Support}',
  CONFIRMED_CLEARED_TO_TRAVEL_ACCEPTED_MEET_WITH_CLINICAL = 'Upon arrival, meet with the clinical, credentialing, payroll, and housing desks.',
  ARRIVED = 'Thank you for your support & patient care!',
}

export enum AssignmentActiveStatusIndex {
  APPLIED = 1,
  SUBMITTED = 2,
  SUBMITTED_NONE_WITH_NO_REMAINING_CREDENTIALS = 3,
  SUBMITTED_POST_NOTICE = 3,
  SUBMITTED_TRAVEL_BOOKED = 4,
  CONFIRMED_TRAVEL_CONFIRMED = 5,
  CONFIRMED_CLEARED_TO_TRAVEL = 6,
  CONFIRMED_ARRIVED = 7,
}
