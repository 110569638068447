import React, { FC } from 'react';
import Badge from '@mui/material/Badge';
import MenuIcon from '../../assets/icons/NavMenuIcon/NavMenuIcon';
import { MenuIcons } from 'enums/menuIcons';
import { EditIcon, IconWrapper } from './Profile.styles';

export interface IProfileBadgeProps {
  onClick: (() => void) | undefined;
}

export const ProfileEditBadge: FC<IProfileBadgeProps> = ({
  children,
  onClick,
}) => (
  <>
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <EditIcon id="badge-edit-icon">
          <IconWrapper>
            <MenuIcon active={true} icon={MenuIcons.Edit} />
          </IconWrapper>
        </EditIcon>
      }
      sx={{ position: 'relative' }}
      onClick={onClick}
    >
      {children}
    </Badge>
  </>
);

export default ProfileEditBadge;
